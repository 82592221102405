@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

body {
	
	font-family: 'Poppins', sans-serif;
}

main {
	max-width: 100%;
	margin:auto;
	overflow: hidden;
	background-color: #ffffff;
}