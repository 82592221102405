:root {
  --color-primario: #FCB03B;
  --color-secundario: #E92F3D;
  --color-backgroud-sombra: #E92F3D30;
  --color-background: #FCF2E6;
  --color-texto: #A1A5A6;
  --color-pronalce1: #73833d;
  --color-pronalce3: #73833d;
  --color-pronalce2: #6d5b46;
  --color-facebook: #3b5998;
  --color-instragram: ;
  --color-likedin: #0e76a8;
  --color-sociales: #73833d;
  --fondo-icono-cumey: rgb(128, 141, 61);
  --fondo-icono-sur: rgb(115, 129, 52);
  --fondo-icono-chocotom: rgb(103, 116, 43);
  --fondo-icono-sanesteban: rgb(89, 103, 33);
}



.convocatorias-container{
 width: 100%;
 margin: auto; 
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: center;
 overflow: hidden;

 top: 0;
 left: 0;
 width: 100%;
 height: 100%;
 background-image: url("../../../design/del_sur/Fondos/Brush_bk_02.png");
 background-size: cover;
 background-position: center;
}



.convocatorias-container .convocatorias-title{
  text-align: center;
  width: 100%;
  margin: auto;
  font-size: 2.5rem;

 }

 .convocatorias-container .convocatorias-title h1{
  font-size: 62px;
  font-weight: 600;
  color: var(--color-pronalce1);
  /*text-shadow: rgba(0, 0, 0, 0.5) 0em 0.1em 0.1em;
    -webkit-text-stroke-width: 0.3px;
    -webkit-text-stroke-color: white;*/

 }

 .convocatorias-container .convocatorias-title h1 img{
  width: 80px;
  z-index: 1;
 }


 .convocatorias-container .convocatorias-img img{
  width: auto;
  height: 300px;
  z-index: 1;
  margin-right: 15vh;
  max-width: 100%; /* Asegura que la imagen nunca desborde su contenedor */
  height: auto;
 }
 
.convocatorias{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin: auto;
  background-image: url("../../../design/politicas/background_pronalce_1080.png");
  padding-bottom: 100px;
}

.table-container {
    width: 90%;
    overflow-x: auto;
    margin-top: 20px;
    margin: auto;
    padding-top: 2%;
    overflow-x: auto;
 
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    font-family: Arial, sans-serif;
    font-size: 14px;
    color: #333;
    display: block; /* Hace que el ancho sea adaptable */
  }
  
  th, td {
    padding: 12px;
    text-align: left;
    border: 1px solid #ddd;
  }
  
  th {
    background-color: var(--color-pronalce3); /* Color azul oscuro */
    color: white;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  td {
    vertical-align: top;
  }
  
  tr:nth-child(even) {
    background-color: #f2f2f2; /* Color gris claro */
  }
  
  tr:hover {
    background-color: #ddd; /* Color gris al hacer hover */
  }
  
  .button-cell {
    text-align: center;
  }
  
  .button-convocatoria {
    z-index: 100;
    width: auto;
    height: auto;
    margin: auto;
    
  }
  
  .button-convocatoria a{
    color: #000000;
    border-radius: 30px;
    border: #fff;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    margin: auto;
    transition: transform 1s;
  }

  .button-convocatoria a:hover{
    cursor: pointer;
    transform: scale(1.05);
    background-color: var(--color-pronalce1);
    color: white;
  }
  

  .fecha_cierre{
    color: #E92F3D;
  }

  .button-convocatoria p{
    text-align: justify;
    margin: auto;
    font-size: 1.4vh;
  }


/* Ajustes para pantallas medianas (tablets y pantallas pequeñas) */
@media (max-width: 1024px) {
  .convocatorias-container {
    flex-direction: column;
    text-align: center;
    padding: 20px;
  }

  .convocatorias-container .convocatorias-title h1 {
    font-size: 48px; /* Reduce el tamaño del texto */
  }

  .convocatorias-container .convocatorias-img img {
    height: 200px; /* Reduce la altura de la imagen */
    margin: 0 auto;
  }

  .table-container {
    padding: 10px;
  }

  table {
    font-size: 12px; /* Reduce el tamaño del texto */
  }

  th, td {
    padding: 8px; /* Reduce el espacio interno de las celdas */
  }
}

/* Ajustes para pantallas pequeñas (smartphones) */
@media (max-width: 768px) {
  .convocatorias-container {
    padding: 15px;
  }

  .convocatorias-container .convocatorias-title h1 {
    font-size: 36px; /* Ajusta el tamaño del texto para pantallas pequeñas */
  }

  .convocatorias-container .convocatorias-img img {
    height: 150px; /* Ajusta la altura de la imagen */
  }

  .table-container {
    width: 100%;
  }

  table {
    font-size: 10px; /* Reduce el texto aún más */
  }

  th, td {
    padding: 6px;
  }

  .button-convocatoria a {
    font-size: 12px; /* Ajusta el tamaño del texto del botón */
  }

  .button-convocatoria p {
    font-size: 10px; /* Ajusta el tamaño del texto de la descripción */
  }
}

/* Ajustes para pantallas muy pequeñas (menos de 480px) */
@media (max-width: 480px) {
  .convocatorias-container {
    padding: 10px;
  }

  .convocatorias-container .convocatorias-title h1 {
    font-size: 28px;
  }

  .convocatorias-container .convocatorias-img img {
    height: 120px;
  }

  table {
    font-size: 8px; /* Texto compacto */
  }

  th, td {
    padding: 4px;
  }

  .button-convocatoria a {
    font-size: 10px;
  }

  .button-convocatoria p {
    font-size: 8px;
  }
}
