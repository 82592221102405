
.grid-404 {
    display: grid;
    width: 100%;
    height: 550px;
    margin: auto;
    padding-top: 50px;

    background-image: url(../../design/politicas/background_pronalce_1080.png);
    background-size: cover;
    background-repeat: round;
    grid-template-areas:
    "error404 error404 error404"
    "contenedor_auto contenedor_auto contenedor_auto"
    
    ;
  }

.error404{
    grid-area: error404;
}

.error404 h1{

    color: var(--color-pronalce3);
    font-size: 96px;
    font-weight: 700;
    margin: auto;
    text-shadow: rgba(0, 0, 0, 0.5) 0em 0.1em 0.1em;
}  


.error404 i{
font-size: 100px;
padding-top: 20px;
}

.error404 a{
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    background-color: #3498db;
    color: #fff;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    }

.error404 a:hover{
     background-color: #2980b9;
}


.contenedor_auto {
    grid-area: contenedor_auto;
    overflow: hidden;

    position: relative;
    height: 125px; /* Ajusta según tus necesidades */
    border-bottom: 5px solid #000; /* Borde blanco en la parte inferior para simular una calle */
    
}

#auto {
    position: absolute;
    left: -350px; /* Comienza fuera del lado izquierdo de la pantalla */
    transform: translateX(0); /* Desplazamiento inicial para dar la sensación de entrada */
    transition: transform 9s linear;
}


@media (max-width: 722px) {

    .error404 i {
        font-size: 70px;
        padding-top: 30px;
    }

    .error404 h1 {
        color: var(--color-pronalce1);
        font-size: 43px;
        font-weight: 700;
        margin: auto;
        text-shadow: rgba(0, 0, 0, 0.5) 0em 0.1em 0.1em;
    }
    
}