.carousel-overlay {
  
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.carousel-content {
  position: relative;
  width: 80%;
  max-width: 900px;
  display: flex;
  height: 80%;
}

.close-button {
  position: absolute;
  top: 0px;
  right: 10%;
  background: none;
  border: none;
  color: white;
  font-size: 25px;
  cursor: pointer;
  z-index: 3000;
}

.carousel-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
  width: 100%;
}

.carousel-slide {
 width: 100%;
  height: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.carousel-image {
  max-width: 40%;
  max-height: 100%;
  object-fit: contain;
  transition: transform 0.3s ease-in-out;
}

.carousel-image:hover {
  cursor: zoom-in;
}

.thumbnail-gallery {
  width: 10%;
  height: 100%;
  overflow-y: scroll;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
}

.thumbnail-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-bottom: 10px;
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 0.3s;
}

.thumbnail-image.active {
  opacity: 1;
  border: 2px solid white;
}

.thumbnail-image:hover {
  opacity: 1;
}

.prev-button,
.next-button {
  background: none;
  border: none;
  color: white;
  font-size: 30px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2000;
}

.prev-button {
  left: 15%;
}

.next-button {
  right: 25%;
}


@media (max-width: 768px) {
  .carousel-content {
    width: 90%;
    height: 90%;
  }

  .carousel-image {
    max-width: 90%;
    max-height: 70%;
  }

  .thumbnail-gallery {
    flex-direction: row;
    width: 100%;
    height: 80px;
    
  }

  .thumbnail-image {
    width: 60px;
    height: 60px;
    margin: 5px;
  }

  .prev-button, .next-button {
    font-size: 50px;
    color: #0e76a8ff;
  }


  .prev-button {
    left: 2%;
  }
  
  .next-button {
    right: 2%;
  }
}