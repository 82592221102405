@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap');
:root {
  --color-primario: #FCB03B;
  --color-secundario: #E92F3D;
  --color-backgroud-sombra: #E92F3D30;
  --color-background: #FCF2E6;
  --color-texto: #A1A5A6;
  --color-pronalce1: #73833d;
  --color-pronalce2: #6d5b46;
  --color-facebook: #3b5998;
  --color-instragram: ;
  --color-likedin: #0e76a8;
  --color-sociales: #73833d;
  --fondo-icono-cumey: rgb(128, 141, 61);
  --fondo-icono-sur: rgb(115, 129, 52);
  --fondo-icono-chocotom: rgb(103, 116, 43);
  --fondo-icono-sanesteban: rgb(89, 103, 33);
}


/*Propiedades del Grid principal*/
.grid-maquila {
  box-shadow: 0px 2px 2px 5px rgba(0, 0, 0, 0.2);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: auto;
  grid-template-areas:
    "maquila maquila maquila"
    "aliados aliados aliados"
    "aliados2 aliados2 aliados2"
    "slider_maquila slider_maquila slider_maquila"
    "blanco blanco blanco"

  ;
}

.grid .menu-principal .menu-bar {
  display: grid;
  grid-template-columns: 5fr 1fr 1fr;
  grid-template-rows: minmax(auto, auto);
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;

}

.maquila {
  grid-area: maquila;
  width: 100%;
  height: 300px;
  margin: auto;
  background-image: url("../../../design/maquila/bk_header_Maquila.webp");
  background-attachment: fixed;
  background-size: Cover;
  background-repeat: no-repeat;
}

.maquila h1 {
  color: #fff;
  font-size: 96px;
  font-weight: 700;
  margin: auto;
  margin-top: 70px;
  text-shadow: rgba(0, 0, 0, 0.5) 0em 0.1em 0.1em;
  -webkit-text-stroke-width: 0.6px;
  -webkit-text-stroke-color: black;
}

.aliados {
  grid-area: aliados;
}

.aliados_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: 800px;
  background-image: url("../../../design/maquila/maquila_image.webp");
  background-size: cover;
  margin: auto;
}

.texto_aliados {
  width: 80%;
  margin: auto;

}

.texto_aliados p{
  font-size: 25px;
  text-align: justify;
}

.texto_aliados .titulo{
  width: 100%;
  margin: auto;
  margin-bottom: 5%;
  font-size: 60px;
  color: var(--color-pronalce1);
  font-weight: 600;
  -webkit-text-stroke-width: 0.6px;
  -webkit-text-stroke-color: black;
}


.aliados .aliados_grid .texto_aliado h1 {
  font-size: 60px; 
  font-weight:900;                 
  color:#73833d;

}

.imagen_aliados img {
  width: 90%;
  border-radius: 20px;
}

.aliados2 {
  grid-area: aliados2;
}

.aliados_grid2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: auto;
  background-color: white;
  margin: auto;
}

.texto_aliados2 {
  width: 80%;
  margin: auto;
}

.texto_aliados2 p{
  font-size: 25px;
  text-align: justify;
}


.texto_aliado2 p {
  font-size: 45px;
}

.texto_aliados2 .titulo{
  width: 100%;
  margin: auto;
  font-size: 60px;
  color: var(--color-pronalce1);
  font-weight: 600;
}

.imagen_aliados2 img {
  width: 100%;
  border-radius: 20px;
  margin: auto;
  margin-top: 10%;
}


.slider_maquila {
  grid-area: slider_maquila;
  width: 100%;
  margin-bottom: -5px;
}


.blanco{
  grid-area: blanco;
  width: 100%;
  height: 70px;
  margin: auto;
  margin-top: -6px;
  background-color: white;
  z-index: 99;
}


@media (max-width: 722px) {


  .header .banner1 {
    display: none;
  }

  .header .menu-principal {
    background-color: rgb(254, 254, 254, 0.60);
    backdrop-filter: blur(8px);
  }




  .maquila {
    grid-area: maquila;
    width: 100%;
    height: 180px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 5px;
    background-size:100% 30vh;
    background-repeat: no-repeat;
    box-shadow: 2px 2px 2px 4px rgb(0 0 0 / 30%);
}

.maquila h1 {
  font-size: 5vh;
  margin-top: 100px;
}


.aliados .aliados_grid {
  display: grid;
  width: 100%;
  height: auto;
  margin: auto;
  background-size:  100% 100%;
  background-repeat: no-repeat;
  box-shadow: 2px 2px 2px 4px rgb(0 0 0 / 30%);
  
}

.aliados .aliados_grid .texto_aliados p {
  width: 100%;
  text-align:left ;
  font-size: 12px;
  margin: 10px;
}


.texto_aliados {
  width: 100%;
}


.texto_aliados .titulo{
  width: 100%;
  margin: auto;
  font-size: 20px;
  color: var(--color-pronalce1);
  font-weight: 600;
  margin-top: 15px;
}


.aliados2 .aliados_grid2 {
  display: flex;
  width: 100%;
  height: auto;
  margin: auto;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: 2px 2px 2px 4px rgb(0 0 0 / 30%);
  flex-direction: column;
  background-color: #FFF;
  
}

.aliados2 .aliados_grid2 .texto_aliados2 p {
  width: 100%;
  text-align:justify ;
  font-size: 12px;
  margin: auto;
 padding: 10px;
}


.texto_aliados2 {
  width: 100%;
}


.texto_aliados2 .titulo{
  width: 100%;
  margin: auto;
  font-size: 20px;
  color: var(--color-pronalce1);
  font-weight: 600;
  margin-top: 15px;
}

.imagen_aliados2 img {
  width: 90%;
  height: auto;
  margin: auto;
  margin-top: 5%;
  box-shadow: 2px 2px 2px 4px rgb(0 0 0 / 30%);
}


.slider_maquila {
z-index: 1;
  margin-top: 10px;
}


.blanco{

  height: 70px;
  margin: auto;
  margin-top: -8px;
  background-color: white;
  z-index: 99;
}

}