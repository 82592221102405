/* Estilos generales del popup */
.cookie-popup {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url("../../design/images/procesos_bk.svg");
    background-size: cover;
    background-position: bottom;
    background-color: #2c3e50;
    color: #ecf0f1;
    padding: 20px;
    text-align: center;
    z-index: 2000;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    font-family: 'Arial', sans-serif;
    border-radius: 10px 10px 0 0;
    animation: slideUp 0.5s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .cookie-popup p {
    font-size: 15px;
    line-height: 1.5;
    margin: 0;
    display: inline-block;
    color: #000;
  }

  .cookie-popup p img {
    width: 2vh;
    margin-right: 10px;
  }
  
  .cookie-popup a {
    color: #3498db;
    margin-left: 10px;
    text-decoration: underline;
    font-weight: bold;
  }
  
  .cookie-popup a:hover {
    color: #2980b9;
  }
  
  /* Botón de aceptar cookies */
  .cookie-popup-accept {
    margin-top: 15px;
    padding: 10px 20px;
    background-color:#006450;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .cookie-popup-accept:hover {
    background-color: #3498db;
  }
  
  /* Botón de cerrar (X) */
  .cookie-popup-close {
    position: absolute;
    top: 10px;
    right: 15px;
    background: none;
    border: none;
    font-size: 24px;
    color: #006450;
    cursor: pointer;
  }
  
  .cookie-popup-close:hover {
    color: #3498db;
  }
  
  /* Animación de deslizamiento */
  @keyframes slideUp {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
  
  /* Estilos responsivos */
  @media (min-width: 768px) {
    .cookie-popup {
      flex-direction: row;
      justify-content: space-between;
      padding: 10px 40px;
    }
  
    .cookie-popup p {
      font-size: 15px;
    }
  
    .cookie-popup-accept {
      margin-top: 0;
      margin-left: 20px;
      font-size: 16px;
      padding: 12px 25px;
    }
  }
  
  @media (max-width: 767px) {
    .cookie-popup {
      padding: 15px;
    }
  
    .cookie-popup p {
      font-size: 14px;
    }
  
    .cookie-popup-accept {
      width: 100%;
      font-size: 14px;
      padding: 10px;
    }
  
    .cookie-popup a {
      display: block;
      margin-top: 10px;
    }
  }
  