:root {
    --color-primario: #FCB03B;
    --color-secundario: #E92F3D;
    --color-backgroud-sombra: #E92F3D30;
    --color-background: #FCF2E6;
    --color-texto: #A1A5A6;
    --color-pronalce1: #2B579E;
    --color-pronalce3: #73833d;
    --color-pronalce2: #6d5b46;
    --color-facebook: #3b5998;
    --color-instragram: ;
    --color-likedin: #0e76a8;
    --color-sociales: #73833d;
    --fondo-icono-cumey: rgb(128, 141, 61);
    --fondo-icono-sur: rgb(115, 129, 52);
    --fondo-icono-chocotom: rgb(103, 116, 43);
    --fondo-icono-sanesteban: rgb(89, 103, 33);
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  li {
    color: #fff;
  }
  
  li {
    list-style: none;
  }
  
  a {
    text-decoration: none;
  }
  
  .footer_area {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    background-image: url("../../design/images/pronalce_bk_footer_01.webp");
    background-repeat: round;
    background-size: 100%;
    background-size: cover;
  }
  
  /*.Items-Inicial{
    grid-area: 1/1/1/5;
    padding-top: 40px;
  }*/
  
  
  .items-Marcas {
    grid-area: 2/2;
    display: flex;
    margin: auto;
  
  }
  
  .items-Marcas h3 {
    --tw-text-opacity: 1;
    color: var(--color-pronalce3 var(--tw-text-opacity));
  }
  
  .items-Negocio h3 {
    --tw-text-opacity: 1;
    color: var(--color-pronalce3 var(--tw-text-opacity));
  }
  
  .items-Negocio a {
    color: #fff;
    font-size: 30px;
    line-height: 35px
  }
  
  .items-Informacion h3 {
    --tw-text-opacity: 1;
    color: var(--color-pronalce3 var(--tw-text-opacity));
  }
  
  .items-Informacion h1 {
    font-size: 20px;
    font-weight: 500;
    line-height: 15px
  }
  
  .items-Ubicación h3 {
    --tw-text-opacity: 1;
    color: var(--color-pronalce3 var(--tw-text-opacity));
  }
  
  .items-Ubicación a {
    color: #fff;
    font-size: 30px;
    line-height: 35px
  }
  
  
  .items-Negocio {
    grid-area: 1/3;
    justify-content: center;
    align-items: center;
    padding-top: 200px;
  }
  
  .items-Informacion {
    grid-area: 1/2;
    justify-content: center;
    align-items: center;
    padding-top: 150px;
  }
  
  .items-Informacion a {
    color: #fff;
  }
  .items-Informacion img {
    width: 250px;
  }
  

  
  .items-Ubicación {
    grid-area: 1/1;
    justify-content: center;
    align-items: center;
    padding-top: 200px;
  }
  
  
  .items-Marcas .forma-icono img {
    width: 100px;
    height: auto;
    margin: auto;
    transition: 0.5s;
  }
  
  .Icono_co {
    width: 25%;
    align-items: center;
    margin: auto;
    padding: 5px;
    border-radius: 30px;
  }
  
  .Icono_co1 {
  display: none;
  }
  
  
  .Icono_sgs {
    width: 30%;
    align-items: center;
    margin: auto;
    padding: 5px;
    border-radius: 30px;
  }
  
  .linea {
    border: 2px solid white;
  }
  
  
  
  /*Copyrigth*/
  
  .copyright {
    width: 100%;
    height: 60px;
    background: rgba(0, 0, 0);
    text-align: center;
    margin-top: -16px;
    font-family: 'Ubuntu', sans-serif;
    bottom: 0;

  }
  
  .copyright i {
 
    color: #d89127;
  }
  
  
  .copyright p {

    color: #7a7a7a;
  }
  
  .copyright p b {
    margin-top: 10px;
    color: #d89127;
  }
  
  
  .copyright hr {
    border: none;
    height: 0px;
    background-color: #7a7a7a;
  }
  
  .copyright img {
    margin-right: 0;
    width: 15px;
    height: 35px;
  }
  
  
  .copyright:hover {
    background: rgba(0, 0, 0, 0.98);
    opacity: .90;
  }
  
  
  
  
  /*--------- @MEDIA PANTALLA 1250px---------------------*/
  @media (max-width: 720px) {
  
    .footer_area {
      width: 100%;
      height: auto;
      grid-template-columns: repeat(1, auto);
      grid-template-areas: 
      "items-Informacion items-Informacion items-Informacion"
      "items-Marcas items-Marcas items-Marcas"
      "items-Ubicación items-Ubicación items-Ubicación";
      background-repeat: no-repeat;
      background-size: cover;
    }
  
    .items-Informacion {
      grid-area: items-Informacion;
      justify-content: center;
      align-items: center;
      padding-top: 150px;
  }
  
  .items-Informacion img {
    width: 200px;
  }
  
  .items-Informacion h1 {
    font-size: 15px;
    font-weight: 500;
    line-height: 10px;
  }
    
  .items-Negocio{
    display: none;
  }
  
  .items-Marcas {
    grid-area: items-Marcas;
    padding-bottom: 30px;
  }
  
  .items-Marcas .forma-icono img {
    width: 75%;
    height: auto;
    margin: auto;
    /* transition: 0.5s; */
  }
  
  .items-Ubicación {
    grid-area: items-Ubicación;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    display: grid;
    grid-template-areas: 
    "forma-icono_sgs forma-icono_sgs forma-icono_sgs"
    "list list list"
    ;
  }
  
  .list-unstyled {
    grid-area: list;
    display: flex;
    margin: auto;
  }
  
  .items-Ubicación a {
    color: #fff;
    font-size: 13px;
    margin: 10px;
  
  }
  
  .items-Ubicación a .planta_trabaja{
  width: 10px;
  height: 10px;
}
  
  .forma-icono_sgs{
    display: flex;
    grid-area: forma-icono_sgs;
  }
  
  .Icono_co1 {
  display: block;
  width: 25%;
  align-items: center;
  margin: auto;
  
  }
  
  .Icono_sgs{
    display: block;
  width: 25%;
  align-items: center;
  margin: auto;
  }
  
  .copyright {
    width: 100%;
    height: 60px;
  
    font-size: 12px;
  }
  
  .copyright p {
    margin-top: -10px;
    color: #7a7a7a;
  }
  
  
  }
  
  
  
  /*Icono WS*/
  .btn-wsp {
    position: fixed;
    width: 50px;
    height: 50px;
    line-height: 50px;
    bottom: 30px;
    right: 20px;
    background: #25d366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 27px;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.3);
    z-index: 100;
    transition: all 300ms ease;
  }
  
  .btn-wsp:hover {
    background: #20ba5b;
    box-shadow: 10px 1px 10px rgba(0, 0, 0, 0.3);
  }
  
  @media only screen and (min-width:320px) and (max-width:768px) {
    .btn-wsp {
      width: 63px;
      height: 63px;
      line-height: 66px;
      right: 5px;
  
    }
  }
  
  /*SOCIAL MEDIA*/
  
  .social {
    display: flex;
    z-index: 100;
  justify-content: center;
  }
  
  .social {
    list-style: none;
  
  }
  
  .social a {
    display: block;
    transition: all 0.1s ease;
    color: white;
    width: 45px;
    height: 45px;
    line-height: clamp(30px, 3vw, 48px);
    font-size: 15px;
    text-align: center;
    margin: 3px;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: .1s ease-in-out;
    transition: .1s ease-in-out;
  }
  
  @media (max-width: 720px) {
    .social a {
      width: clamp(35px, 3vw, 48px);
      height: clamp(35px, 3vw, 48px);
      line-height: clamp(35px, 3vw, 48px);
    }
  
  }
  
  
  .social  .icon-facebook {
    font-size: 22px;
    border-radius: 18px;
  
  
  }
  
  /* Establecemos los colores de cada red social, aprovechando su class */
  .social  .icon-instagram {
    font-size: 22px;
    border-radius: 15px;
  }
  
  .social .icon-linkedin {
    font-size: 22px;
    border-radius: 15px;
  }
  
  .social .icon-instagram:hover {
    position: relative;
    -webkit-transform: scale(1.1);
    transform: scale(1.2);
    cursor: pointer;
    color: #fff;
    /* background: #444; */
    border-radius: 10px;
    z-index: 100;
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285aeb 90%);
    font-size: 20px;
  }
  
  .social .icon-facebook:hover {
    position: relative;
    -webkit-transform: scale(1.1);
    transform: scale(1.2);
    cursor: pointer;
    color: #fff;
    /* background: #444; */
    border-radius: 10px;
    z-index: 100;
    background: var(--color-facebook);
    font-size: 20px;
  }
  
  .social .icon-linkedin:hover {
    position: relative;
    -webkit-transform: scale(1.1);
    transform: scale(1.2);
    cursor: pointer;
    color: #fff;
    /*background: #444;
    */
    border-radius: 10px;
    z-index: 100;
    background: var(--color-likedin);
    font-size: 20px;
  }
  
  
  
  