/* General Reset */
.linea-etica-container {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f1f1f1;
  color: #333;
}

/*Header Linea Etica*/
.Header_linea-etica {
  background-image: url(../../../design/images/Header_Etica.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  padding: 10px;
  text-align: center;
  width: 100%;
  height: 450px; /* Ajustado para pantallas pequeñas */
}

.title-etica {
  font-size: 24px;
  margin-top: 20px;
}

/* Main Content */
.main-content-etica {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.44);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.main-content-etica h1 {
  font-size: 24px;
  color: #73833d;
  margin-bottom: 15px;
}

.main-content-etica p {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: justify;
}

/* Info Section */
.info-section {
  display: flex;
  flex-direction: column; /* Ajustado para pantallas pequeñas */
  gap: 20px;
}

.info-section div {
  background: #f1f1f1;
  padding: 15px;
  border-radius: 8px;
  text-align: center;
}

.info-section h2 {
  font-size: 20px;
  color: #333;
  margin-bottom: 10px;
}

/* Reportable Cases */
.reportable-cases {
  margin-top: 40px;
}

.reportable-cases h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

.cases-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.cases-grid div {
  background-color: #e9e9e9;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* Responsive Design */
@media (max-width: 768px) {
  .Header_linea-etica {
    height: 400px;
  }

  .main-content-etica {
    padding: 15px;
  }

  .main-content-etica h1 {
    font-size: 20px;
  }

  .info-section {
    flex-direction: column;
  }

  .cases-grid {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
}

@media (max-width: 480px) {
  .Header_linea-etica {
    height: 350px;
    font-size: 16px;
  }

  .main-content-etica {
    padding: 10px;
  }

  .main-content-etica h1 {
    font-size: 18px;
  }

  .info-section div {
    font-size: 14px;
  }

  .cases-grid {
    grid-template-columns: 1fr;
  }
}
