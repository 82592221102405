@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap');

:root {
  --color-primario: #FCB03B;
  --color-secundario: #E92F3D;
  --color-backgroud-sombra: #E92F3D30;
  --color-background: #fff;
  --color-texto: #A1A5A6;
  --color-pronalce1: #73833d;
  --color-pronalce2: #6d5b46;
  --color-facebook: #3b5998;
  --color-instragram: ;
  --color-likedin: #0e76a8;
  --color-sociales: #73833d;
  --fondo-icono-cumey: rgb(128, 141, 61);
  --fondo-icono-sur: rgb(115, 129, 52);
  --fondo-icono-chocotom: rgb(103, 116, 43);
  --fondo-icono-sanesteban: rgb(89, 103, 33);
}



/*GENERALES*/
* {
  font-family: 'Ubuntu', sans-serif;
  text-align: center;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none !important;
}


html {
  scroll-behavior: smooth;
}

body {

  justify-content: center;
  max-width: auto;
  min-height: 100%;
  background-repeat: no-repeat;
  background-position: center;

}

footer{
  margin-top: 2%;
}

/*Propiedades del Grid principal*/
.grid {
  box-shadow: 0px 2px 2px 5px rgba(0, 0, 0, 0.2);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: auto;
  grid-template-areas:
    "video-principal video-principal video-principal"
    "procesos procesos procesos"
    "alpina alpina alpina"
    "Slider_instagram_1 Slider_instagram_1 Slider_instagram_1"
    "Slider_instagram_2 Slider_instagram_2 Slider_instagram_2"
    "granos granos granos"
    "NuestrosClientes NuestrosClientes NuestrosClientes"
    "organic organic organic"
    "chucks chucks chucks"
    "galery_prod galery_prod galery_prod"
    "Chocotom Chocotom Chocotom"
    "vainilla vainilla vainilla"
    "contact_us contact_us contact_us"
  ;
}






/*----------------------------------------------Propiedades video*/
.grid .video-principal {
  grid-area: video-principal;
  width: 100%;
  height: Auto;
  box-shadow: 0px 30px 13px 9px rgb(0 0 0 / 20%);
  z-index: -1000;
  margin-top: -140px;
}

.img-video {

  display: none;
}

.video-principal .video-avena {
  width: 100%;
  height: auto;
  z-index: -1;
}


.mensaje {
  color: #FFF;
  grid-area: video-principal;
  width: 100%;
  height: 100px;
  z-index: 1;
  margin: auto;
  margin-top: -75vh;
  margin-bottom: 500px;
}

.mensaje h1 {
  color: white;
  color: white;
  text-shadow: rgba(0, 0, 0, 0.5) 0em 0.1em 0.1em;
  font-size: 96px;
  font-weight: 700;
  font-style: bold;
  line-height: 0.9em;
  letter-spacing: -3px;
}



.procesos {
  grid-area: procesos;
  width: 100%;
  height: auto;
  margin: auto;
  z-index: 2;
  background-image: url("../../../design/images/procesos_bk.svg");
  background-color: rgba(0, 0, 0, 0.05);
  background-size: 100%;
  background-repeat: no-repeat;
  margin-top: -100px;
  padding-bottom: 80px;


}


.procesos p {
  color: var(--color-pronalce2);
  text-align: center;
  font-size: 56px;
  margin: auto;

}

.procesos-texto {
  grid-area: procesos-texto;
  width: 80%;
  margin: auto;

}

.procesos-texto p {
  font-size: 25px;
  color: var(--color-texto);
  padding-top: 10vh;
  text-align: center;
}


.video-procesos {
  width: 80%;
  height: 700px;
  grid-area: video-procesos;
  padding: 25px;
  margin: auto;
  opacity: 1 !important;
  z-index: 1;

}



/*NUESTROS CLIENTES*/

.NuestrosClientes {
  grid-area: NuestrosClientes;
  background-color: #ffffff;
  width: 100%;
  margin: auto;
}

.aliado {
  grid-area: aliado;
  color: var(--color-sociales);
  text-align: center;
  font-size: 65px;
  margin: auto;

}



.organic {
  grid-area: organic;
  display: flex;
  width: 100%;
  height: auto;
  background-image: url("../../../design/del_sur/Fondos/Main_banner_01_bk.png");
  background-size: cover;
  margin: auto;
  margin-top: 5%;
  margin-bottom: 5%;
  z-index: 100;
}


.organic .img_organic {
  width: 100%;
  height: auto;
  margin: auto;
  z-index: 1;
}

.organic .img_organic img {
  width: 60%;
  height: 100%;
  margin: auto;
  rotate: 10deg;
}

.organic .txt_organic {
  width: 100%;
  height: auto;
  margin: auto;
  margin-top: 10%;
  margin-bottom: 10%;
  padding-right: 5%;
  text-align: left;
}

.organic .txt_organic h3 {
  font-size: 40px;
  text-align: left;
}

.organic .txt_organic h1 {
  font-size: 110px;
  font-weight: 800;
  line-height: 100%;
  text-align: left;

}

.galeria {
  grid-area: galery_prod;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  z-index: 2;
}

.galeria h1 {
  color: var(--color-pronalce1);
  font-size: 50px;
  font-weight: 800;
  text-align: center;
  margin: auto;
  margin-bottom: 20px;
}

.grid_galeria_productos {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}


.grid_galeria_productos .items_productos {
  display: flex;
  position: relative;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 20px;
  width: calc(33% - 40px);
  box-sizing: border-box;
  text-align: center;
  z-index: 1;
}


.grid_galeria_productos .items_productos::before {
  content: '';
  position: absolute;
  top: 10px;
  left: 10px;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  background-color: rgba(0, 0, 0, 0.1);
  z-index: -1;
  border-radius: 10px;
}

.grid_galeria_productos .items_productos:hover::before {
  background-color: rgba(0, 17, 255, 0.1);
  

}

.grid_galeria_productos .items_productos img {
  max-width: 10ch;
  height: auto;
  margin: 20px;
  z-index: 1;

}

.image_galeria {
  position: relative;
  display: inline-block;
  transition: 0.5s ease-in-out;
}

.image_galeria::before {

  content: '';
  position: absolute;
  top: 20%;
  left: -5px;
  width: calc(90% + 30px);
  height: calc(40% + 20px);
  background-color: rgba(17, 0, 255, 0.1);
  z-index: -1;
  transform: rotate(-10deg);
  border-radius: 10px;

}

.image_galeria.galery1::before {
  background-color: #123595;
}

.image_galeria.galery2::before {
  background-color: #009579;
}

.image_galeria.galery3::before {
  background-color: #6cace4;
}

.image_galeria.galery4::before {
  background-color: #89532f;
}

.image_galeria.galery5::before {
  background-color: #fa4616;
}

.image_galeria.galery6::before {
  background-color: #dbc99b;
}

.image_galeria:hover::before {
  transform: rotate(10deg);
}

.image_galeria:hover {

  transform: rotate(10deg) scale(1.3);
}

.grid_galeria_productos .items_productos h2 {
  color: #73833d;
  margin-top: 0;
  font-size: 1.5em;
}

.grid_galeria_productos .items_productos p {
 padding: 10%;
 margin: auto; 

}

.Chocotom{
  display: flex;
  grid-area: Chocotom;
  margin-top: 10%;
  background-image: url("../../../design/del_sur/Fondos/Chocotom_bk.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 600px;
}

.Chocotom .img_chocotom{
  width: 100%;
}

.Chocotom .img_chocotom img{
  position: relative;
  min-height: 120%;
  top: -20%;
}

.Chocotom .txt_chocotom{
  width: 100%;
  margin: auto;
  text-align: left;
  color: white;
  font-size: 20px;
  padding: 20px

}

.Chocotom .txt_chocotom h1{
  font-size: 80px;
  font-weight: 900;
  line-height: 100%;
  text-align: left;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;

}

.Chocotom .txt_chocotom h3{
  font-size: 25px;
  line-height: 100%;
  text-align: left;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;

}

.Chocotom .txt_chocotom button{
  text-align: center;
  margin: auto;
  background-color: rgb(254 254 254 / 10%);
  border: none;
  color: white;
  padding: 15px 50px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  border-radius: 30px;
  transition: 1s;
}


.Chocotom .txt_chocotom button:hover{

  background-color: rgb(254 254 254 / 50%);
  scale: 1.3;
}



.granos{
  grid-area: granos;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  background-image: url("../../../design/del_sur/Fondos/background_pronalce_1080.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.granos h1{
  text-align: center;
  margin-bottom: 20px;
  font-size: 3em;
  color: #73833d;
  font-weight: 600;
}

.granos_elements{
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  justify-content: center;
  transition: 1s;
  margin: auto;
}


.granos_elements .granos_product:hover{
  scale: 1.1;
}
  

.granos_product{
  background-color: #fff;
  border-radius: 30px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  width: calc(20% - 20px);
  margin-bottom: 8%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 1s;
}


.image_granos{
  position: relative;
  width: 100%;
  /* Aspect ratio 4:3 */
}


.image_granos img{
  top: 0;
  left: 0;
  width: 90%;
  margin: auto;
  padding: 5%;
  object-fit: cover;
  border-radius: 25%;
  
}


.granos h2{
  font-size: 20px;
  margin: 10px 0;
  color: #6d5b46;
  font-weight: 600;
}

.granos p{
  padding: 0 10px;
  flex-grow: 1;
  font-size: 14px;
  text-align: center;
  
}

.button_more {
  display: block;
  padding: 10px 0;
  background-color: var(--color-likedin);
  color: white;
  text-decoration: none;
  font-weight: bold;
  border-radius: 0 0 10px 10px;
  transition: background-color 0.3s;
}

.button_more:hover {
  background-color: #555;
}


.Publi_vainilla{
  grid-area: vainilla;
  display: flex;
  flex-direction: column;
}


.vainilla{
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  height: 90%;
  background-image: url("../../../design/del_sur/Fondos/Brush_bk_01.png");
  background-size: cover;
  margin: auto;
  z-index: 100;

}

.vainilla .img_vainilla {
  width: 100%;
  height: auto;
  margin: auto;
  z-index: 1;
  
}

.vainilla .img_vainilla img {
  width: 60%;
  height: 100%;
  margin: auto;
  margin-right: 33%;
 


}

.vainilla .txt_vainilla {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin: auto;
  margin-top: 10%;
  padding-right: 5%;
  text-align: left;
}

.vainilla .txt_vainilla h3 {
  color: #000;
  font-weight: 600;
  font-size: 35px;
  text-align: justify;
  margin: auto;
  padding-left: 35%;
  
}

.vainilla .txt_vainilla img {
  position: relative;
  width: 50%;
  height: auto;
  margin: auto;
  margin-left: 50%;
}


.text2{
  display: flex;
  flex-direction: column;
  width: 60%;
  height: auto;
  margin: auto;
  margin: auto;
  text-align: center;
  align-items: flex-start;
}

.text2 h1{
  color: #000;
  font-weight: 600;
  font-size: 30px;
  text-align: justify;
  margin: auto;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 5%;
}

.text2 p{
  color: #000;
  font-weight: 400;
  font-size: 17px;
  text-align: justify;
  margin: auto;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 2%;
}

.text2 button{
  color: #000;
  font-weight: 400;
  font-size: 20px;
  padding-left: 8%;
  padding-right: 8%;
  padding-top:1%;
  padding-bottom:1% ;
  border-radius: 30px;
  margin-left: 10%;
  margin-top: 5%;
  color: #000;

}

.contact_us{
  grid-area: contact_us;
  display: flex;
  background-image: url("../../../design/images/pronalce_fondo1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  margin: auto;
  margin-top: 5%;
  z-index: 100;
}

.contact_us h1{
font-size: 40px;
line-height: 0px;
font-weight: 600;
text-align: center;
margin: auto;
color: #c67f43;
}

.contact_us span{
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: #fff;
}

.contact_us .txt_contact{
  display: flex;
  flex-direction: column;
  font-size: 16px;
  width: 35%;
  height: auto;
  margin:auto;
  margin-top: 5%;
  margin-bottom: 5%;
  margin-left: 15%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
  color:#fff;
  border-radius: 15%;
}

@media screen and (max-width: 1350px) {

  .organic .txt_organic h3 {
    font-size: 5vh;
  }

  .organic .txt_organic h1 {
    font-size: 15vh;
  }

  .image_galeria::before {
    top: 20%;
    left: 22%;
    width: calc(50% + 20px);
    height: calc(40% + 20px);

  }

  .grid_galeria_productos .items_productos {
    flex-direction: column;

  }

}

@media screen and (max-width: 1210px) {

  .organic .txt_organic h3 {
    font-size: 4vh;
  }

  .organic .txt_organic h1 {
    font-size: 15vh;
  }

  .grid_galeria_productos .items_productos {
    flex-direction: column;
    width: calc(50% - 40px);
  }

  .image_galeria::before {

    top: 20%;
    left: 22%;
    width: calc(50% + 20px);
    height: calc(40% + 20px);


  }

  .Chocotom .img_chocotom img{
    width: 80%;
    min-height: 80%;
    top: 8%;
    
  }
  
  .granos_product {
    width: calc(50% - 20px);
}

.contact_us .txt_contact {

  width: 100%;
  margin-left: 0%;
}

.contact_us h1 {
  line-height: 20px;
}
}


@media (max-width: 722px) {


  .grid {
    box-shadow: 0px 2px 2px 5px rgba(0, 0, 0, 0.2);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: auto;
    margin-top: 7%;
    grid-template-areas:
      "video-principal video-principal video-principal"
      "organic organic organic"
       "alpina alpina alpina"
    "Slider_instagram_1 Slider_instagram_1 Slider_instagram_1"
      "Slider_instagram_2 Slider_instagram_2 Slider_instagram_2"
      "procesos procesos procesos"
      "granos granos granos"
      "NuestrosClientes NuestrosClientes NuestrosClientes"
      "galery_prod galery_prod galery_prod"
      "Chocotom Chocotom Chocotom"
      "vainilla vainilla vainilla"
      "contact_us contact_us contact_us"
    ;
 
  }
  


  .grid .video-principal {
    display: none;
  }


  .video-procesos {
    margin-top: 5%;
    width: 100%;
    height: 400px;
  }

  .galeria {
padding: 0px;
  }

  .galeria h1{
    font-size: 30px;
  }
  .grid_galeria_productos .items_productos {
    flex-direction: column;
    width: calc(45% - 10px);
  }


  .grid_galeria_productos .items_productos img {
    max-width: 10ch;
    height: auto;
    margin-bottom: 5px;
    z-index: 1;
  
  }

  .grid_galeria_productos .items_productos p {
    display: none;
  }

  .grid_galeria_productos .items_productos p {
    display: none;
  }

  .image_galeria::before {

    top: 20%;
    left: 0%;
    width: calc(80% + 20px);
    height: calc(40% + 20px);


  }

  .NuestrosClientes {

    
    width: 100%;
    margin: auto;
  }

  .organic {
    grid-area: organic;
    display: flex;
    width: 100%;
    height: auto;
    margin: auto;
    margin-top: 9%;
    z-index: 100;
  }


  .organic .txt_organic h3 {
    font-size: 1.5vh;
  }

  .organic .txt_organic h1 {
    font-size: 4vh;
  }

  .Chocotom .txt_chocotom h1 {
    font-size: 3vh;
  }

  .Chocotom{
    flex-direction: column;
  }
  .Chocotom .txt_chocotom h3 {
    font-size: 2vh;
  }

  .Chocotom .img_chocotom img{
    width: auto;
    height: 40vh;
    margin: auto;
    margin-bottom: 1px;
    
  }


  .Chocotom .txt_chocotom button:hover{
    scale: 1.1;
  }

  .granos_product {
    width: calc(50% - 20px);
}

.granos_product p{
  display: none;
}

.granos_elements {
  width: 95%;
  margin: auto;
}

.granos h2 {
  font-size: 18px;
}

}