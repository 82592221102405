
/*pagina de hojuelas naturales*/

.primary_hojuela {
    display: grid;
    grid-template-columns: 1fr 1fr;
    overflow: hidden;
    width: 100%;
    height: 60vh;
    background-image: url("../../../design/del_sur/Elements/Hojuelas_header.png");
    background-size: cover;

  
  }
  
  .primary_hojuela .txt_hojuela {
    margin: auto;
  }
  
  .primary_hojuela .title_hojuela {
    margin: auto 25%;
    font-size: 16vh;
    font-weight: 700;
    color: #004296;
  }
  
  .tlt_hojuela {
    width: 100%;
    height: auto;
    margin: auto;
    background: linear-gradient(70deg, #2c589f, #90c4fe);
    font-size: 2.2vh;
    margin: auto;
  
  }
  
  .tlt_hojuela h1 {
    margin: auto;
    align-items: center;
    padding-top: 5px;
  }
  
  .avenas_hojuela {
    width: 100%;
    height: 100vh;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
  
    background-image: url("../../../design/del_sur/Fondos/Home_alimentacion_bk.png");
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    background-color: #e1e1e1;
  }
  
  .avenas_hojuela .txt_avenas_hojuelas h2 {
    width: 100%;
    margin: 3% 20%;
    font-size: 6.2vh;
    font-weight: 800;
    color: #2b579e;
    text-align: justify;
  
  }
  
  .avenas_hojuela .txt_avenas_hojuelas h3 {
    font-size: 3.5vh;
    font-weight: 600;
    color: #939394;
    text-align: justify;
    margin: 0% 20%;
  }
  
  .avenas_hojuela .txt_avenas_hojuelas .boton_avena {
    width: 25vh;
    height: 6vh;
    margin: 3% 20%;
    margin-right: 55%;
    background-color: rgba(0, 66, 150, 0.3);
    color: #2b579e;
    border-color: #004296;
    font-size: 2.5vh;
  }
  
  
  
  .avenas_hojuela .img_avenas_hojuelas {
    width: 100%;
  
  }
  
  .avenas_hojuela .nutricion {
  
    width: 80vh;
    height: 15vh;
    margin: 3% 20%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
  
  
  .avenas_hojuela .nutricion1,
  .avenas_hojuela .nutricion2,
  .avenas_hojuela .nutricion3,
  .avenas_hojuela .nutricion4 {
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin: auto;
    align-items: center;
    font-size: 2.5vh;
    color: #939394;
  }
  
  .avenas_hojuela .nutricion p {
    text-align: justify;
    margin: auto;
  
  }
  
  .avenas_hojuela .nutricion img {
    width: 10vh;
    margin: auto;
  
  }
  
  
  .avenas_hojuela .nutricion img:hover {
    transform: scale(1.1);
  }
  
  
  .avenas_hojuela .img_avenas_hojuelas img {
    width: 100%;
    padding-top: 10%;
    margin: auto;
  }
  
  /*Hojuela sabor fresa*/
  
  
  .avenas_hojuela_fresa {
    width: 100%;
    height: 90vh;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    background-color: #e1e1e1;
  }
  
  .avenas_hojuela_fresa .txt_avenas_hojuelas h2 {
    width: 100%;
    margin: 3% 0%;
    font-size: 6.2vh;
    font-weight: 800;
    color: #2b579e;
    text-align: justify;
  
  }
  
  .avenas_hojuela_fresa .txt_avenas_hojuelas h3 {
    font-size: 3.5vh;
    font-weight: 600;
    color: #939394;
    text-align: justify;
    margin: 0% 0%;
  }
  
  .avenas_hojuela_fresa .txt_avenas_hojuelas .boton_avena {
    width: 25vh;
    height: 6vh;
    margin: 3% 0%;
    margin-right: 55%;
    background-color: rgba(0, 66, 150, 0.3);
    color: #2b579e;
    border-color: #004296;
    font-size: 2.5vh;
  }
  
  
  
  .avenas_hojuela_fresa .img_avenas_hojuelas {
    width: 100%;
  
  }
  
  .avenas_hojuela_fresa .nutricion {
  
    width: 80%;
    height: 15vh;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
  
  
  .avenas_hojuela_fresa .nutricion1,
  .avenas_hojuela_fresa .nutricion2,
  .avenas_hojuela_fresa .nutricion3,
  .avenas_hojuela_fresa .nutricion4 {
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin: auto;
    align-items: center;
    font-size: 2.5vh;
    color: #939394;
  }
  
  .avenas_hojuela_fresa .nutricion p {
    text-align: justify;
    margin: auto;
  
  }
  
  .avenas_hojuela_fresa .nutricion img {
    width: 10vh;
    margin: auto;
  
  }
  
  
  .avenas_hojuela_fresa .nutricion img:hover {
    transform: scale(1.1);
  }
  
  
  .avenas_hojuela_fresa .img_avenas_hojuelas img {
    width: 100%;
    height: 90%;
    margin: auto;
  }
  
  
  /*Hojuela sabor banano*/
  
  
  .avenas_hojuela_banano {
    width: 100%;
    height: 100vh;
    padding-top: 10px;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    background-color: #e1e1e1;
    background-image: url("../../../design/del_sur/Fondos/Home_alimentacion_bk.png");
  }
  
  .avenas_hojuela_banano .txt_avenas_hojuelas h2 {
    width: 100%;
    margin: 3% 20%;
    font-size: 6.2vh;
    font-weight: 800;
    color: #2b579e;
    text-align: justify;
  
  }
  
  .avenas_hojuela_banano .txt_avenas_hojuelas h3 {
    font-size: 3.5vh;
    font-weight: 600;
    color: #939394;
    text-align: justify;
    margin: 0% 20%;
  }
  
  .avenas_hojuela_banano .txt_avenas_hojuelas .boton_avena {
    width: 25vh;
    height: 6vh;
    margin: 3% 20%;
    margin-right: 55%;
    background-color: rgba(0, 66, 150, 0.3);
    color: #2b579e;
    border-color: #004296;
    font-size: 2.5vh;
  }
  
  
  
  .avenas_hojuela_banano .img_avenas_hojuelas {
    width: 100%;
  
  }
  
  .avenas_hojuela_banano .nutricion {
  
    width: 80vh;
    height: 15vh;
    margin: 3% 20%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
  
  
  .avenas_hojuela_banano .nutricion1,
  .avenas_hojuela_banano .nutricion2,
  .avenas_hojuela_banano .nutricion3,
  .avenas_hojuela_banano .nutricion4 {
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin: auto;
    align-items: center;
    font-size: 2.5vh;
    color: #939394;
  }
  
  .avenas_hojuela_banano .nutricion p {
    text-align: justify;
    margin: auto;
  
  }
  
  .avenas_hojuela_banano .nutricion img {
    width: 10vh;
    margin: auto;
  
  }
  
  
  .avenas_hojuela_banano .nutricion img:hover {
    transform: scale(1.1);
  }
  
  
  .avenas_hojuela_banano .img_avenas_hojuelas img {
    width: 100%;
    height: 80%;
    margin: auto;
  }
  
  
  /*Hojuela sabor vainilla*/
  
  
  .avenas_hojuela_vainilla {
    width: 100%;
    height: 90vh;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    background-color: #e1e1e1;
  }
  
  .avenas_hojuela_vainilla .txt_avenas_hojuelas h2 {
    width: 100%;
    margin: 3% 0%;
    font-size: 6.2vh;
    font-weight: 800;
    color: #2b579e;
    text-align: justify;
  
  }
  
  .avenas_hojuela_vainilla .txt_avenas_hojuelas h3 {
    font-size: 3.5vh;
    font-weight: 600;
    color: #939394;
    text-align: justify;
    margin: 0% 0%;
  }
  
  .avenas_hojuela_vainilla .txt_avenas_hojuelas .boton_avena {
    width: 25vh;
    height: 6vh;
    margin: 3% 0%;
    margin-right: 55%;
    background-color: rgba(0, 66, 150, 0.3);
    color: #2b579e;
    border-color: #004296;
    font-size: 2.5vh;
  }
  
  
  
  .avenas_hojuela_vainilla .img_avenas_hojuelas {
    width: 100%;
  
  }
  
  .avenas_hojuela_vainilla .nutricion {
  
    width: 80%;
    height: 15vh;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
  
  
  .avenas_hojuela_vainilla .nutricion1,
  .avenas_hojuela_vainilla .nutricion2,
  .avenas_hojuela_vainilla .nutricion3,
  .avenas_hojuela_vainilla .nutricion4 {
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin: auto;
    align-items: center;
    font-size: 2.5vh;
    color: #939394;
  }
  
  .avenas_hojuela_vainilla .nutricion p {
    text-align: justify;
    margin: auto;
  
  }
  
  .avenas_hojuela_vainilla .nutricion img {
    width: 10vh;
    margin: auto;
  
  }
  
  
  .avenas_hojuela_vainilla .nutricion img:hover {
    transform: scale(1.1);
  }
  
  
  .avenas_hojuela_vainilla .img_avenas_hojuelas img {
    width: 100%;
    height: 90%;
    margin: auto;
  }

  @media (max-width: 722px) {

  .primary_hojuela {

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 40vh;
  }

  .primary_hojuela .title_hojuela {
    margin: auto 0% -15%;
    font-size: 10vh;
    text-shadow: #d8c8b4 0 -1px;
  }

  .tlt_hojuela {
    height: auto;
    font-size: 1.1em;
  }

  .avenas_hojuela {
    grid-template-columns: 1fr;
  }

  .avenas_hojuela .txt_avenas_hojuelas h2 {
    margin: auto;
    text-align: center;
    font-size: 4.2vh;
  }

  .avenas_hojuela .txt_avenas_hojuelas h3 {
    font-size: 3.2vh;
    font-weight: 600;
    color: #939394;
    text-align: center;
    margin: auto;

  }

  .avenas_hojuela .txt_avenas_hojuelas .boton_avena {
    width: 25vh;
    height: 6vh;
    margin: auto;
    margin-top: 5%;
    margin-bottom: 5%;
    border-radius: 80%;
  }

  .avenas_hojuela .nutricion {
    width: 100%;
    height: auto;
    margin: auto;
    grid-template-columns: 1fr 1fr;
  }


  .avenas_hojuela .nutricion1,
  .avenas_hojuela .nutricion2,
  .avenas_hojuela .nutricion3,
  .avenas_hojuela .nutricion4 {
    grid-template-columns: 1fr 4fr;
    text-align: justify;
    justify-items: start;
    margin-left: 0;
    font-size: 1.8vh;
  }

  .avenas_hojuela .nutricion1,
  .avenas_hojuela .nutricion2,
  .avenas_hojuela .nutricion3,
  .avenas_hojuela .nutricion4 p {
    margin-left: 0;
  }


  .avenas_hojuela .nutricion1,
  .avenas_hojuela .nutricion2,
  .avenas_hojuela .nutricion3,
  .avenas_hojuela .nutricion4 img {
    width: 7vh;

  }

  .avenas_hojuela .nutricion img {
    width: 7vh;
  }

  .avenas_hojuela .img_avenas_hojuelas img {
    width: 80%;
    padding-top: 0;
    margin-top: -15vh;
  }

  .avenas_hojuela_fresa {
    height: auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      "txt_avena_fresa txt_avena_fresa txt_avena_fresa"
      "img_avena_fresa img_avena_fresa img_avena_fresa"
    ;

  }

  .avenas_hojuela_fresa .txt_avenas_hojuelas {
    grid-area: txt_avena_fresa;
    height: auto;
  }

  .avenas_hojuela_fresa .img_avenas_hojuelas {
    grid-area: img_avena_fresa;
    height: auto;
  }

  .avenas_hojuela_fresa .txt_avenas_hojuelas h2 {
    margin: auto;
    text-align: center;
    font-size: 4.2vh;
  }

  .avenas_hojuela_fresa .txt_avenas_hojuelas h3 {
    font-size: 3.2vh;
    font-weight: 600;
    text-align: center;
    margin: auto;
  }

  .avenas_hojuela_fresa .txt_avenas_hojuelas .boton_avena {
    width: 25vh;
    height: 6vh;
    margin: auto;
    margin-top: 5%;
    margin-bottom: 5%;
    border-radius: 80%;
  }


  .avenas_hojuela_fresa .nutricion {
    width: 100%;
    height: auto;
    margin: auto;
    grid-template-columns: 1fr 1fr;
  }


  .avenas_hojuela_fresa .nutricion1,
  .avenas_hojuela_fresa .nutricion2,
  .avenas_hojuela_fresa .nutricion3,
  .avenas_hojuela_fresa .nutricion4 {
    grid-template-columns: 1fr 4fr;
    text-align: justify;
    justify-items: start;
    margin-left: 0;
    font-size: 1.8vh;
  }

  .avenas_hojuela_fresa .nutricion1,
  .avenas_hojuela_fresa .nutricion2,
  .avenas_hojuela_fresa .nutricion3,
  .avenas_hojuela_fresa .nutricion4 p {
    margin-left: 0;
  }


  .avenas_hojuela_fresa .nutricion1,
  .avenas_hojuela_fresa .nutricion2,
  .avenas_hojuela_fresa .nutricion3,
  .avenas_hojuela_fresa .nutricion4 img {
    width: 7vh;

  }

  .avenas_hojuela_fresa .nutricion img {
    width: 7vh;
  }

  .avenas_hojuela_fresa .img_avenas_hojuelas img {
    width: 70%;
    height: auto;
  }

  .avenas_hojuela_banano {
    grid-template-columns: 1fr;
  }

  .avenas_hojuela_banano .txt_avenas_hojuelas h2 {
    margin: auto;
    text-align: center;
    font-size: 4.2vh;
  }

  .avenas_hojuela_banano .txt_avenas_hojuelas h3 {
    font-size: 3.2vh;
    font-weight: 600;
    text-align: center;
    margin: auto;

  }

  .avenas_hojuela_banano .txt_avenas_hojuelas .boton_avena {
    width: 25vh;
    height: 6vh;
    margin: auto;
    margin-top: 5%;
    margin-bottom: 5%;
    border-radius: 80%;
  }

  .avenas_hojuela_banano .nutricion {
    width: 100%;
    height: auto;
    margin: auto;
    grid-template-columns: 1fr 1fr;
  }

  .avenas_hojuela_banano .nutricion1,
  .avenas_hojuela_banano .nutricion2,
  .avenas_hojuela_banano .nutricion3,
  .avenas_hojuela_banano .nutricion4 {
    grid-template-columns: 1fr 4fr;
    text-align: justify;
    justify-items: start;
    margin-left: 0;
    font-size: 1.8vh;
  }

  .avenas_hojuela_banano .nutricion1,
  .avenas_hojuela_banano .nutricion2,
  .avenas_hojuela_banano .nutricion3,
  .avenas_hojuela_banano .nutricion4 p {
    margin-left: 0;
  }


  .avenas_hojuela_banano .nutricion1,
  .avenas_hojuela_banano .nutricion2,
  .avenas_hojuela_banano .nutricion3,
  .avenas_hojuela_banano .nutricion4 img {
    width: 7vh;

  }

  .avenas_hojuela_banano .nutricion img {
    width: 7vh;
  }

  .avenas_hojuela_banano .img_avenas_hojuelas img {
    width: 80%;
    height: auto;
    padding-top: 0;
    margin-top: -13vh;
  }

  .avenas_hojuela_vainilla {
    height: auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      "txt_avena_vainilla txt_avena_vainilla txt_avena_vainilla"
      "img_avena_vainilla img_avena_vainilla img_avena_vainilla"
    ;

  }

  .avenas_hojuela_vainilla .txt_avenas_hojuelas {
    grid-area: txt_avena_vainilla;
    height: auto;
  }

  .avenas_hojuela_vainilla .img_avenas_hojuelas {
    grid-area: img_avena_vainilla;
    height: auto;
  }

  .avenas_hojuela_vainilla .txt_avenas_hojuelas h2 {
    margin: auto;
    text-align: center;
    font-size: 4.2vh;
  }

  .avenas_hojuela_vainilla .txt_avenas_hojuelas h3 {
    font-size: 3.2vh;
    font-weight: 600;
    text-align: center;
    margin: auto;
  }

  .avenas_hojuela_vainilla .txt_avenas_hojuelas .boton_avena {
    width: 25vh;
    height: 6vh;
    margin: auto;
    margin-top: 5%;
    margin-bottom: 5%;
    border-radius: 80%;
  }

  .avenas_hojuela_vainilla .nutricion {
    width: 100%;
    height: auto;
    margin: auto;
    grid-template-columns: 1fr 1fr;
  }

  .avenas_hojuela_vainilla .nutricion1,
  .avenas_hojuela_vainilla .nutricion2,
  .avenas_hojuela_vainilla .nutricion3,
  .avenas_hojuela_vainilla .nutricion4 {
    grid-template-columns: 1fr 4fr;
    text-align: justify;
    justify-items: start;
    margin-left: 0;
    font-size: 1.8vh;
  }

  .avenas_hojuela_vainilla .nutricion1,
  .avenas_hojuela_vainilla .nutricion2,
  .avenas_hojuela_vainilla .nutricion3,
  .avenas_hojuela_vainilla .nutricion4 p {
    margin-left: 0;
  }


  .avenas_hojuela_vainilla .nutricion1,
  .avenas_hojuela_vainilla .nutricion2,
  .avenas_hojuela_vainilla .nutricion3,
  .avenas_hojuela_vainilla .nutricion4 img {
    width: 7vh;
  }

  .avenas_hojuela_vainilla .nutricion img {
    width: 7vh;
  }

  .avenas_hojuela_vainilla .img_avenas_hojuelas img {
    width: 80%;
    height: auto;
  }








  

  }
