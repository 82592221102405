
/*BEBIDAS*/


.primary_bebidas {
    display: grid;
    grid-template-columns: 1fr 1fr;
    overflow: hidden;
    width: 100%;
    height: 60vh;
    background-image: url("../../../design/del_sur/Elements/Bebidas_header.png");
    background-size: cover;
  
  
  }
  
  .primary_bebidas .txt_bebidas {
    margin: auto;
  }
  
  .primary_bebidas .title_bebidas {
    margin: auto 25%;
    font-size: 16vh;
    font-weight: 700;
    color: #eb726f;
  }
  
  .tlt_bebidas {
    width: 100%;
    height: 65px;
    margin: auto;
    background: linear-gradient(70deg, #d14d4a, #ea706d);
    font-size: 4vh;
    margin: auto;
  
  }
  
  .tlt_bebidas h1 {
    margin: auto;
    align-items: center;
    padding-top: 5px;
  }
  
  
  
  .avenas_bebidas {
    width: 100%;
    height: 125vh;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    background-image: url("../../../design/del_sur/Fondos/Bebidas_bk.png");
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    background-color: #e1e1e1;
  }
  
  .avenas_bebidas .txt_avenas_bebidas h2 {
    width: 100%;
    margin: auto;
    font-size: 6.2vh;
    font-weight: 800;
    color: #e56966;
  
  }
  
  .avenas_bebidas .txt_avenas_bebidas .sabores {
    display: grid;
    grid-template-columns: 1fr 5fr 1fr;
    width: 70%;
    margin: auto;
  }
  
  .avenas_bebidas .txt_avenas_bebidas .sabores .Preentaciones h3 {
    margin: auto;
    text-align: center;
  }
  
  
  .avenas_bebidas .txt_avenas_bebidas .sabores_txt h3 {
    margin: auto;
    text-align: center;
  }
  
  
  .avenas_bebidas .txt_avenas_bebidas h3 {
  
    font-size: 2.5vh;
    font-weight: 600;
    color: #939394;
    text-align: justify;
    margin: 0% 20%;
  }
  
  .avenas_bebidas .txt_avenas_bebidas .boton_bebidas {
    width: 25vh;
    height: 6vh;
    margin: 3% 20%;
    margin-right: 55%;
    background-color: rgba(0, 66, 150, 0.3);
    color: #2b579e;
    border-color: #004296;
    font-size: 2.5vh;
  }
  
  
  
  .avenas_bebidas .img_avenas_bebidas {
    width: 100%;
    height: 50vh;
  
  }
  
  .avenas_bebidas .nutricion {
  
    width: 80vh;
    height: 25vh;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-bottom: 10vh;
  
  }
  
  .avenas_bebidas .nutricion1,
  .avenas_bebidas .nutricion2,
  .avenas_bebidas .nutricion3 {
    display: grid;
    grid-template-columns: 1fr;
    margin: auto;
    align-items: center;
    font-size: 1.6vh;
    font-weight: 800;
    color: #939394;
  
  }
  
  .avenas_bebidas .nutricion p {
    text-align: center;
    margin: auto;
    font-weight: 800;
  
  }
  
  .avenas_bebidas .nutricion img {
    width: 8vh;
    margin: auto;
  
  }
  
  
  .avenas_bebidas .nutricion img:hover {
    transform: scale(1.1);
  }
  
  
  .avenas_bebidas .img_avenas_bebidas img {
    width: 70%;
    height: auto;
    padding-top: 2%;
    margin: auto;
  }
  
  
  
  .bebidas_instagram {
    width: 100%;
    height: auto;
    background-color: #e1e1e1;
    margin: auto;
    padding-bottom: 50px;
  }
  
  
  .bebidas_instagram h2 {
    margin: auto;
    font-size: 6.2vh;
    font-weight: 800;
    margin: auto;
    margin-bottom: 5%;
    color: #eb726f;
  }
  



  @media (max-width: 722px) {

    .primary_bebidas .title_bebidas {
      margin: auto 0% -15%;
      font-size: 10vh;
      text-shadow: #fff 0 -2px;
    }
  
    .tlt_bebidas {
      height: auto;
      font-size: 1.1em;
    }
  
    .avenas_bebidas {
      height: auto;
  
    }
  
    .avenas_bebidas .img_avenas_bebidas img {
      width: 100%;
      margin-top: 8%;
      margin-bottom: 8%;
    }
  
    .avenas_bebidas .img_avenas_bebidas {
      height: auto;
  
    }
  
    .avenas_bebidas .txt_avenas_bebidas .sabores {
      display: grid;
      grid-template-columns: 1fr;
      width: 100%;
      margin: auto;
    }
  
    .avenas_bebidas .txt_avenas_bebidas .sabores .Preentaciones h3 {
      margin: auto;
      text-align: center;
      font-size: 2.5vh;
      padding-bottom: 1vh;
    }
  
  
    .avenas_bebidas .txt_avenas_bebidas .sabores_txt h3 {
      margin: auto;
      text-align: center;
      font-size: 2vh;
  
    }
  
  
    .avenas_bebidas .txt_avenas_bebidas h2 {
      text-align: center;
      font-size: 3.5vh;
    }
  
    .avenas_bebidas .txt_avenas_bebidas .boton_bebidas {
      width: 25vh;
      height: 6vh;
      margin: auto;
      margin-top: 5%;
      margin-bottom: 5%;
      border-radius: 80%;
    }
  
  
    .avenas_bebidas .nutricion {
  
      width: 100%;
      height: auto;
      margin: auto;
      margin-bottom: 8vh;
      display: grid;
      grid-template-columns: 1fr;
  
    }
  
    .bebidas_instagram h2 {
      font-size: 3.2vh;
    }
  



  }













  