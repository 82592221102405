@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap');
:root {
  --color-primario: #FCB03B;
  --color-secundario: #E92F3D;
  --color-backgroud-sombra: #E92F3D30;
  --color-background: #FCF2E6;
  --color-texto: #A1A5A6;
  --color-pronalce1: #73833d;
  --color-pronalce2: #6d5b46;
  --color-facebook: #3b5998;
  --color-instragram: ;
  --color-likedin: #0e76a8;
  --color-sociales: #73833d;
  --fondo-icono-cumey: rgb(128, 141, 61);
  --fondo-icono-sur: rgb(115, 129, 52);
  --fondo-icono-chocotom: rgb(103, 116, 43);
  --fondo-icono-sanesteban: rgb(89, 103, 33);
}


.grid-exporta {
  box-shadow: 0px 2px 2px 5px rgba(0, 0, 0, 0.2);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: auto;
  grid-template-areas:
    "exporta exporta exporta"
    "expo_mapa expo_mapa expo_mapa"
    "expo_2 expo_2 expo_2"
  ;
}


.exporta {
  grid-area: exporta;
  width: 100%;
  height: 320px;
  margin: auto;
  margin-bottom: 5px;
  background-image: url("../../../design/exportaciones/bk_header_exporta.webp");
  background-attachment: initial;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 2px 2px 2px 4px rgba(0, 0, 0, 0.3);


}

.exporta h1 {
  color: #fff;
  font-size: 96px;
  font-weight: 700;
  margin: auto;
  margin-top: 70px;
  text-shadow: rgba(0, 0, 0, 0.5) 0em 0.1em 0.1em;
  -webkit-text-stroke-width: 0.6px;
  -webkit-text-stroke-color: black;
}

.expo_mapa{
  grid-area: expo_mapa;
  width: 100%;
  height: auto;
}

.grid_mapa{
  display: grid;
  grid-template-columns: 1fr;
  margin: auto;
}


.grid_mapa {
width: 100%;
}

.grid_mapa .mapa_img{
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: auto;
  margin: auto;
  background-color: rgba(245, 245 ,245);
  
}

.grid_mapa .mapa_img img{
  width: 100%;
  height: auto;
}

.grid_mapa .mapa_img .estiba_img{
  margin: auto;
}

.grid_mapa .mapa_img .estiba_img h1{
  font-size: 60px;
  font-weight: 800;
  color: #3b5998;
}


.grid_mapa .mapa_img .estiba_img p{
  width: 80%;
  text-align:justify;
  padding:10px;
  font-size: 25px;
  margin: auto;
}

.grid_mapa .mapa_txt{
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  margin: auto;

  padding-top: 5%;
  padding-bottom: 5%;
  background-image: url("../../../design/exportaciones/presencia_map.webp");
  background-size: cover;
  background-repeat: no-repeat;
}

.grid_mapa .mapa_txt .texto{
  margin: auto;
}

.grid_mapa .mapa_txt .texto p{
  width: 80%;
  text-align:justify;
  padding:10px;
  font-size: 25px;
  margin: auto;
}


.expo_2{
  grid-area: expo_2;
  width: 90%;
  height: 300PX;
  background-color: #25d366;
  margin: auto;
}


.footer {
  grid-area: footer;
  width: 100%;
  z-index: 10;
}



.copyright {
  grid-area: copyright;

}



@media (max-width: 722px) {


  .header .menu-principal{
    background-color: rgb(254, 254, 254, 0.60);
    backdrop-filter: blur(8px);
  }

.exporta {
    grid-area: exporta;
    height: 180px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 5px;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: 2px 2px 2px 4px rgb(0 0 0 / 30%);

}

.exporta h1 {
  font-size: 5vh;
  margin-top: 100px;
}

.grid_mapa .mapa_img {
  display: flex;
  width: 100%;
  height: 100%;
  margin: auto;
  flex-direction: column;


  
}

.grid_mapa .mapa_img .estiba_img p {
  width: 100%;
  text-align: left;
  padding: 10px;
  font-size: 15px;
  line-height: 18px;
  margin: auto;
}

.grid_mapa .mapa_img img {
  width: 90%;
  box-shadow: 0px 2px 2px 2px rgb(0 0 0 / 20%);
  height: auto;
  margin: auto;
}


.grid_mapa .mapa_img .estiba_img h1 {
  font-size: 20px;
  margin-top: 30px;
}



.grid_mapa .mapa_txt {
  width: 100%;
  height: auto;
  background-size:100% 90% ;
  background-position: center;
  

}

.grid_mapa .mapa_txt .texto h1 {
  font-size: 20px;
  margin-top: 30px;

}

.grid_mapa .mapa_txt .texto p {
  width: 100%;
  text-align: left;
  padding: 10px;
  font-size: 15px;
  line-height: 18px;
  margin: auto;
}

.footer {
margin-top: -40px;
}


}