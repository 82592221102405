
/*MEZCLAS LISTAS*/


.primary_mezclas {
    display: grid;
    grid-template-columns: 1fr 1fr;
    overflow: hidden;
    width: 100%;
    height: 60vh;
    background-image: url("../../../design/del_sur/Elements/Mezclas_header.png");
    background-size: cover;
    background-size: contain;
    background-repeat: round;
  
  }
  
  .primary_mezclas .txt_mezclas {
    margin: auto;
  }
  
  .primary_mezclas .title_mezclas {
    margin: auto 25%;
    font-size: 16vh;
    font-weight: 700;
    color: #644b39;
    line-height: 100px;
  }
  
  .tlt_mezclas {
    width: 100%;
  height: auto;
    margin: auto;
    background: linear-gradient(70deg, #6a4f3c, #c78f69);
    font-size: 4vh;
    margin: auto;
  
  }
  
  .tlt_mezclas h1 {
    margin: auto;
    align-items: center;
    padding-top: 5px;
  }
  
  
  
  .avenas_mezclas {
    width: 100%;
    height: 125vh;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    background-color: #e1e1e1;
  }
  
  .avenas_mezclas .txt_mezclas h2 {
    width: 100%;
    margin: auto;
    font-size: 7vh;
    font-family: "Segoe UI";
    font-weight: 800;
    color: #e9401a;
    margin-top: 5%;
  
  }
  
  .avenas_mezclas .txt_mezclas .sabores {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 30%;
    margin: auto;
  }
  
  .avenas_mezclas .txt_mezclas .sabores .Preentaciones h3 {
    margin: auto;
    text-align: justify;
  
    font-size: 4vh;
  }
  
  
  .avenas_mezclas .txt_mezclas .sabores_txt h3 {
    margin: auto;
    text-align: center;
  }
  
  
  .avenas_mezclas .txt_mezclas h3 {
  
    font-size: 2.5vh;
    font-weight: 600;
    color: #939394;
    text-align: justify;
    margin: 0% 20%;
  }
  
  .avenas_mezclas .txt_mezclas .boton_mezclas {
    width: 30vh;
    height: 7vh;
    margin: 5% 20%;
    margin-right: 55%;
    background-color: rgba(0, 66, 150, 0.1);
    color: #2b579e;
    border-color: #004296;
    font-size: 2.5vh;
  }
  
  
  
  .avenas_mezclas .img_avenas_mezclas {
    width: 100%;
    height: 50vh;
  
  }
  
  
  
  
  .avenas_mezclas .img_avenas_mezclas img {
    width: 100%;
    height: auto;
    padding-top: 2%;
    margin: auto;
    
  }
  
  
  .avena_pancake {
    width: 100%;
    height: 100vh;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-image: url("../../../design/del_sur/Fondos/Home_alimentacion_bk.png");
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    background-color: #e1e1e1;
  }
  
  .avena_pancake .txt_avenas_pancackes h2 {
    width: 100%;
    margin: 3% 20%;
    font-size: 6.2vh;
    font-weight: 800;
    color: #684e3b;
    text-align: justify;
  
  }
  
  .avena_pancake .txt_avenas_pancackes h3 {
    font-size: 3.5vh;
    font-weight: 600;
    color: #939394;
    text-align: justify;
    margin: 0% 20%;
  }
  
  .avena_pancake .txt_avenas_pancackes .boton_avena {
    width: 25vh;
    height: 6vh;
    margin: 3% 20%;
    margin-right: 55%;
    background-color: rgba(0, 66, 150, 0.3);
    color: #2b579e;
    border-color: #004296;
    font-size: 2.5vh;
  }
  
  
  
  .avena_pancake .img_avenas_pancake {
    width: 100%;
  
  }
  
  .avena_pancake .nutricion {
  
    width: 80%;
    height: 15vh;
    margin: 12% 10%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  
  
  .avena_pancake .nutricion1,
  .avena_pancake .nutricion2,
  .avena_pancake .nutricion3 {
    display: grid;
    grid-template-columns: 1fr;
    margin: auto;
    align-items: center;
    font-size: 2.5vh;
    color: #939394;
  }
  
  .avena_pancake .nutricion p {
    text-align: center;
    margin: auto;
  
  }
  
  .avena_pancake .nutricion img {
    width: 10vh;
    margin: auto;
  
  }
  
  
  .avena_pancake .nutricion img:hover {
    transform: scale(1.1);
  }
  
  
  .avena_pancake .img_avenas_pancake img {
    width: 100%;
    padding-top: 10%;
    margin: auto;
  }
  

  @media (max-width: 722px) {


    .primary_mezclas {

      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      height: 30vh;
    }
    
    .primary_mezclas .title_mezclas {
      margin: auto 0% 10%;
      font-size:8vh;
      font-weight: 780;
      text-shadow: #fff 0 -3px;
      text-align: justify;
      line-height: 8vh;
    }
    
    .tlt_mezclas {
      height: auto;
      font-size: 1.1em;
    }
    
    .avena_pancake {
      grid-template-columns: 1fr;
      height: 110vh;
    }
    
    .avena_pancake .txt_avenas_pancackes h2 {
      margin: auto;
      text-align: center;
      font-size: 4.2vh;
    }
    
    .avena_pancake .txt_avenas_pancackes h3{
      font-size: 3.2vh;
      font-weight: 600;
      color: #939394;
      text-align: center;
      margin: auto;
    
    }
    
    .avena_pancake .txt_avenas_pancackes .boton_avena{
      width: 25vh;
      height: 6vh;
      margin: auto;
      margin-top: 5%;
      margin-bottom: 5%;
      border-radius: 80%;
    }
    
    .avena_pancake .nutricion {
      width: 100%;
      height: auto;
      margin: 12% 0%;
      text-align: center;
    
    }
    
    .avena_pancake .img_avenas_pancake img{
      width: 70%;
      margin-top: -23vh;
      box-shadow: 2px 2px 2px 4px rgb(0 0 0 / 30%);
      border-radius: 10%;
    }
    
    .avenas_mezclas{
      height: auto;
      grid-template-areas: 
      "txt_mezclas txt_mezclas txt_mezclas"
      "img_avenas_mezclas img_avenas_mezclas img_avenas_mezclas";
    }
    .avenas_mezclas .img_avenas_mezclas{
      height: auto;
      grid-area: img_avenas_mezclas;
    }
    
    .txt_mezclas{
      grid-area: txt_mezclas;
      margin-top: -15vh;
    }
    
    .avenas_mezclas .txt_mezclas h2{
      font-size: 6vh;
      text-align: center;
      -webkit-text-stroke-width: 0.6px;
      -webkit-text-stroke-color: #fff;
      text-shadow: rgba(0, 0, 0, 0.5) 0em 0.1em 0.1em;
    }
    .avenas_mezclas .txt_mezclas .sabores{
      width: 100%;
    }
    
    .avenas_mezclas .txt_mezclas .sabores .Preentaciones h3{
      font-size: 3vh;
      margin-top: 3vh;
      text-align: center;
    }
    
    .avenas_mezclas .txt_mezclas .boton_mezclas{
      width: 20vh;
      height: 7vh;
      margin: 5% 5%;
      margin-right: 0%;
      border-radius: 50%;
      font-size: 2.5vh;
    }
    
    .avenas_mezclas .img_avenas_mezclas img{
      padding: 5%;
      box-shadow: 2px 2px 2px 4px rgb(0 0 0 / 30%);
      border-radius: 30%;
      margin-bottom: 10%;
      margin-top: 5%;
    }



  }