@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap');

:root {
  --color-primario: #FCB03B;
  --color-secundario: #E92F3D;
  --color-backgroud-sombra: #E92F3D30;
  --color-background: #FCF2E6;
  --color-texto: #A1A5A6;
  --color-pronalce1: #73833d;
  --color-pronalce2: #6d5b46;
  --color-facebook: #3b5998;
  --color-instragram: ;
  --color-likedin: #0e76a8;
  --color-sociales: #73833d;
  --fondo-icono-cumey: rgb(128, 141, 61);
  --fondo-icono-sur: rgb(115, 129, 52);
  --fondo-icono-chocotom: rgb(103, 116, 43);
  --fondo-icono-sanesteban: rgb(89, 103, 33);
  --color-efectos: #aec65f;
}



ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
}

header {
  position: relative;
  width: 100%;
  height: auto;
;
}

.header-up {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background-color: rgb(115, 131, 61, 0.9);
  transition: transform 0.3s ease;
  backdrop-filter: blur(2px);
  width: 100%;
  height: Auto;
}


.header-up .logo {
  width: 25%;
  height: auto;
  margin: auto;

}

.header-up .mensaje-header {
  width: 100%;
  height: auto;
  margin: auto;

}

.header-up .mensaje-header p {
  padding: 5px;
  font-size: 25px;
  color: #fff;
  text-align: center;
}

.header-up .Contactanos {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 60%;
  height: auto;
  margin: auto;
  margin-top: 2%;
}

.header-up .Contactanos button {
  z-index: 100;
  width: 110px;
  height: 36px;
  background-color: #006450;
  color: #ccc;
  border-radius: 56px;
  border: #ccc;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  margin: auto;
  transition: transform 1s;

}

.header-up .Contactanos button:hover {
  cursor: pointer;
  transform: scale(1.05);
  background-color: #0e76a8;

}

.dark-mode-toggle {
  z-index: 1000;
  display: none;
  /* Alineación de los elementos */
  align-items: center;
  /* Alineación vertical de los elementos */
  justify-content: end;
  /* Alineación horizontal de los elementos */
  flex-direction: row;
  /* Cambia la dirección de los elementos */
}

.dark-mode-text {
  font-size: 14px;
  margin-right: 10px;
  /* Espacio entre el texto y el interruptor */
  color: #fff;
  /* Color del texto */
}

.toggle-label {
  display: block;
  width: 44px;
  height: 22px;
  background-color: #888;
  border-radius: 25px;
  position: relative;
  cursor: pointer;
}




.header {
  transition: background 0.3s ease;
}

.header-down {
  transition: background-color 0.3s ease;
}




.toggle-label::after {
  content: '';
  position: absolute;
  width: 22px;
  height: 22px;
  background-color: #fff;
  border-radius: 50%;
  top: 0;
  left: 0;
  transition: left 0.3s ease;
}

#dark-mode-toggle-checkbox {
  display: none;
}

#dark-mode-toggle-checkbox:checked+.toggle-label {
  background-color: #2c3e50;
  /* Color de fondo oscuro */
}

#dark-mode-toggle-checkbox:checked+.toggle-label::after {
  left: 25px;
}

.header-down {
  display: grid;
  grid-template-columns: 2fr 5fr 2fr;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(90px);
  width: 100%;
  height: 50px;
  transition: transform 0.5s ease;
}



.down-1 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  margin: auto;
}

.down-1 span {
  width: 100%;
  font-size: 25px;
  height: auto;
  margin-top: 12px;
  color: #fff;
  padding-left: 10px;
}

 .down-1 span:hover {
  color: #0e76a8;
}

 .down-1 #menu-2 {
  text-align: center;
}

.down-1 #menu-2 ul:hover {
  background-color: #0e76a8;
}


 .down-1 #menu-2 ul {
  list-style: none;
  padding: 0;
  margin: 0;
}


.down-1 #menu-2 li {
  padding: 12px;
}


.down-1 #menu-2 a {
  text-decoration: none;
  color: #fff;
  transition: color 0.3s ease;
}

.down-1 #menu-2 a .logo_sur_w {
width: 35px;
margin-bottom:2%;
}


 .down-1 #menu-2 .submenu {
  width: 100%;
  height: 300px;
  margin: auto;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: rgba(51, 51, 51, 0.8);
  backdrop-filter: blur(74px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

}


 .down-1 #menu-2 .submenu .submenu-1-item1 {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin: auto;


}





 .down-1 #menu-2 .submenu.visible {
  transition: 1s ease-in-out;
  display: block;
}


.donw-2 {
  width: 100%;
  height: 100%;

  justify-content: space-evenly;
  margin: auto;
}


 .donw-2 .list-pro {
  display: flex;
  justify-content: flex-start;

}

 .donw-2 .list-pro li {
  width: auto !important;
  height: auto;
  padding: 12px;
}

 .donw-2 .list-pro li:hover {
  background-color: #0e76a8;
}


.donw-2 .list-pro a {
  text-decoration: none;
  color: #fff;
  transition: color 0.3s ease;
  
}


 .donw-3 {
  width: 100%;
  height: 100%;
  justify-content: center;
  margin: auto;
  display: grid;
  grid-template-columns: 2fr 1fr;
}


 .donw-3 .list-shop {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  padding: 12px;


}

 .donw-3 .list-shop a {
  width: 100%;
  height: auto;
  font-size: 18px;
  margin: auto;
  text-decoration: none;
  color: #fff;
  transition: color 0.3s ease;



}

.donw-3 .list-shop a:hover {
  color: #0e76a8;
}

.submenu-1-item1 .gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.submenu-1-item1 .gallery-item {
  margin: 20px;
  margin-top: 3%;
  text-align: center;
  cursor: pointer;
  transition: transform 1s;
}

.submenu-1-item1 .gallery-item img {
  border-radius: 50%;
  /* Hace la imagen circular */
  width: 130px;
  height: 130px;

}

.submenu-1-item1 .gallery-item p {
  font-size: 18px;
  margin-top: 10px;
  -webkit-text-stroke-width: 0.6px;
  -webkit-text-stroke-color: black;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}

.submenu-1-item1 .gallery-item:hover {
  transform: scale(1.3);
}

.gallery-item img {
  transition: opacity 1s ease;
  /* Agrega una transición de opacidad */
}



.submenu .submenu-2 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  justify-content: space-evenly;
}


.submenu .submenu-2 .btn-nosotros {
  z-index: 100;
  width: 200px;
  height: 40px;
  background-color: #006450;
  color: #ccc;
  border-radius: 56px;
  border: #ccc;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  margin: auto;
  transition: transform 1s;
}

.submenu .submenu-2 .btn-nosotros:hover {
  cursor: pointer;
  transform: scale(1.05);
  background-color: #0e76a8;

}

.donw-2 .list-pro li img {
  margin: auto;
  width: 12px;
}


.submenu .submenu-2 .btn-nosotros img {
  margin: auto;
  width: 10px;
}

.submenu .submenu-2 .btn-recetas {
  z-index: 100;
  width: 200px;
  height: 40px;
  background-color: #006450;
  color: #ccc;
  border-radius: 56px;
  border: #ccc;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  margin: auto;
  transition: transform 1s;
}



.submenu .submenu-2 .btn-recetas:hover {
  cursor: pointer;
  transform: scale(1.05);
  background-color: #0e76a8;

}


#menu-1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: rgb(0, 0, 0, 0.60);
  backdrop-filter: blur(74px);
  z-index: 600;
  display: grid;
  grid-template-columns: 2fr 5fr 2fr;
}

#menu-1 .down-1 .logo_pronalce {

  width: 100%;
  height: 50px;

}

.menu-oculto {
  display: none;
  /* Para ocultar el menú oculto inicialmente */
}

.menu-visible .menu-oculto {
  display: block;
  /* Para mostrar el menú oculto cuando el menú principal está visible */
}

.menu-visible {
  transform: translateY(0);
  transition: transform 0.3s ease-out;
}

.menu-hidden {
  transform: translateY(-100%);
  transition: transform 0.3s ease-out;
}

.header-mobile{
display: none;
}

.subheader{
  display: none;
}


.header-up {
  transition: transform 0.3s ease;
}

.header.hidden .header-up {
  transform: translateY(-100%);
}


.header-down-hidden {
  background-color: #333c;
  backdrop-filter: blur(74px);
  transform: translateY(0%);
  transition: transform 0.3s ease;

}

.header-down-visible {
  transform: translateY(0);
  transition: transform 0.3s ease;
  top: 0;
  position:fixed;
  z-index: 5000;

}




@media (max-width: 900px) {

  .header{
    display: none;
  }

  .header-mobile{
    display: flex;
    position: fixed;
    justify-content: space-between;
    background-color: #0e76a8 ;
    Top: 0px;
    width: 100%;
    height: 60px;
    z-index: 4000;
  }


  .header-mobile .logo-mobile span{
    width: 60px;
    margin: 5px;
    font-size: 25px;
    padding: 15px 0px 0px 10px;
    color: #fff;
  }

  
  .header-mobile img{
    width: 100px;
    margin: auto;
  }

  .header-mobile span{
    font-size: 25px;
    color: #fff;
    margin: auto;
    padding: 15px 0px 0px 10px;
    margin-right: 10px;
  }



  .header-mobile .social{
    margin: 5px 15px;
  }

 .subheader
  {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: fixed;
    margin:auto;
    margin-top: 5%;
    padding: 15%;
    background-color: #fff;
    z-index: 1000;

  }
  .subheader .list{
    display: flex;
    height: 35px;
    margin: 5% 0px 10px 10px;
    font-size: 3.5vh;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 600;
    color: #0e76a8;
    background: linear-gradient(currentColor, currentColor);
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-size: 0% 2px;
    transition: all 0.5s;


  }

  .subheader .social a{
    color: #0e76a8;
    font-size: 30px;
    margin: auto 10px;
  }



}