.container {

  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f7f9fc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 5%;
}

.container h1{
  color: #0056b3;
  font-size: 30px;
  font-weight: 900;
}

.input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 2px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  transition: border-color 0.3s;
}

.input:focus {
  border-color: #007bff;
  outline: none;
}

.dropdown-list {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  max-height: 150px;
  overflow-y: auto;
  margin-bottom: 10px;
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}

.button {
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #0056b3;
}

.error-message {
  color: red;
  margin-top: 10px;
  font-weight: bold;
  text-align: center;
}

.carousel-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.carousel-content {
  max-width: 90%;
  max-height: 90%;
  position: relative;
}

.carousel-image {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.carousel-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #fff;
  border: none;
  font-size: 20px;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 5px;
}

.carousel-close:hover {
  background-color: #ff4d4d;
  color: white;
}

/* Media Queries para dispositivos móviles */
@media (max-width: 768px) {
  .container {
    max-width: 90%;
    margin-top: 20%;
  }



  .input {
    font-size: 14px;
    padding: 8px;
  }

  .button {
    padding: 10px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .container {
    max-width: 100%;
    margin-top: 20%;
  }

  .input {
    font-size: 12px;
    
  }

  .button {
    padding: 8px;
    font-size: 12px;
  }

  .dropdown-list {
    max-height: 100px;
  }

  .carousel-content {
    max-width: 95%;
    max-height: 95%;
  }

  .carousel-container {
    flex-direction: column;
  }

  
}
