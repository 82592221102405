@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap");

:root {
  --color-primario: #fcb03b;
  --color-secundario: #e92f3d;
  --color-backgroud-sombra: #e92f3d30;
  --color-background: #fcf2e6;
  --color-texto: #00161c;
  --color-pronalce1: #73833d;
  --color-pronalce2: #6d5b46;
  --color-facebook: #3b5998;
  --color-likedin: #0e76a8;
  --color-sociales: #73833d;
}

body {
  font-family: 'Ubuntu', sans-serif;
  color: var(--color-texto);
  margin: 0;
  padding: 0;
}

.industria-container {
  color: var(--color-texto);
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../../../design/industria/background_pronalce_1080.6037cdbed364838753a1.png");
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-line-image img {
  width: 100%;
  max-width: 80%;
  height: auto;
}

.industria-header {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(115, 131, 61, 0.4) 100%);
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1454px;
}

.industria-header .palito {
  width: 8px;
  height: 100px;
  background-color: var(--color-pronalce1);
  margin-right: 30px;
}

.industria-header h2 {
  text-align: left;
  font-size: 2rem;
  color: var(--color-pronalce1);
  margin: 0;
}

.industria-header .highlight {
  color: var(--color-pronalce1);
  font-weight: bold;
  font-size: 3.5rem;
}

.txt_industria p {
  text-align: justify;
  font-size: 1.8rem;
  line-height: 1.6;
  max-width: 1200px;
  margin: 20px 0;
  padding: 10px;
  color: var(--color-texto);
}

.industria-content {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.circular-gallery-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  margin-bottom: 2%;
  margin-top: -5%;
}

.circular-gallery {

  position: relative;
  width: 100%;
  max-width: 600px;
  height: 100%;
  max-height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
  padding: 20px;
  margin-bottom: 50px;
}

.central-image img {
  width: 70%;
  height: auto;
  border-radius: 50%;
  border: 4px solid var(--color-pronalce1);
  transition: transform 0.3s ease;
}

.circle-item {
  position: absolute;
  width: 20%;
  height: 20%;
  text-align: center;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.circle-item img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 3px solid var(--color-pronalce1);
  transition: transform 0.3s ease;
}

.circle-item:hover img {
  transform: scale(1.1);
}

.circle-item p {
  font-size: 1rem;
  color: var(--color-texto);
  margin-top: 5px;
}

.description-box {
  text-align: center;
  padding: 15px;
  margin-top: 10px;
  margin: auto;
}

.description-box h3 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: var(--color-pronalce1);
  text-align: justify;
}

.description-box li {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: var(--color-pronalce1);
  line-height: 1.5;
  text-align: justify;
  list-style-type: disc;
}

.description-box li a {
  color: var(--color-pronalce1);
}

.description-box li a:hover {
  color: var(--color-likedin);
}

.circle-item.selected {
  border: 3px solid #007acc;
  border-radius: 50%;
  transition: border 0.3s ease;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .industria-header h2 { font-size: 2.5rem; }
}

@media (max-width: 768px) {
  .circular-gallery-container {
    grid-template-columns: 1fr;
  }
  
  .circular-gallery {
    max-width: 400px;
    max-height: 400px;
  }

  .description-box h3 {
    font-size: 2rem;
  }

  .central-image img {
    width: 50%;
  }

  .circle-item {
    width: 20%;
    height: 20%;
  }

  .circle-item p {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .industria-header h2 {
    font-size: 1.8rem;
  }
  
  .central-image img {
    width: 40%;
  }

  .circle-item {
    width: 25%;
    height: 25%;
  }
  
  .description-box h3 {
    font-size: 1.5rem;
  }

  .circle-item p {
    font-size: 0.8rem;
  }
}
