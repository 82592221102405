

/*Harinas*/


/*pagina de hojuelas naturales*/

.primary_harinas {
    display: grid;
    grid-template-columns: 1fr 1fr;
    overflow: hidden;
    width: 100%;
    height: 60vh;
    background-image: url("../../../design/del_sur/Elements/Harina_header.png");
    
    background-size: cover;
    background-repeat: no-repeat;
  
  }
  
  .primary_harinas .txt_harinas {
    margin: auto;
  }
  
  .primary_harinas .title_harinas {
    margin: auto 25%;
    font-size: 160px;
    font-weight: 700;
    color: #9d6118;
  }
  
  .tlt_harinas {
    width: 100%;
    height: auto;
    margin: auto;
    background: linear-gradient(70deg, #996018, #ac7f35);
    font-size: 40px;
    margin: auto;
  
  }
  
  .tlt_harinas h1 {
    margin: auto;
    align-items: center;
    padding-top: 5px;
  }
  
  
  
  .avenas_harinas {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    height: 100vh;
    margin: auto;
    overflow: hidden;
    background-image: url("../../../design/del_sur/Elements/Harinas_bk.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #e1e1e1;
  }
  
  .avenas_harinas .txt_avenas_harinas h2 {
    width: 100%;
    margin: 3% 20%;
    font-size: 60px;
    font-weight: 800;
    color: #9e6820;
    text-align: justify;
  
  }
  
  .avenas_harinas .txt_avenas_harinas h3 {
  
    font-size: 25px;
    font-weight: 600;
    color: #939394;
    text-align: justify;
    margin: 0% 20%;
  }
  
  .avenas_harinas .txt_avenas_harinas .boton_harinas {
    width: 200px;
    height: 40px;
    margin: 3% 20%;
    margin-right: 55%;
    background-color: rgba(0, 66, 150, 0.3);
    color: #2b579e;
    border-color: #004296;
    font-size: 2.5vh;
  }
  
  
  
  .avenas_harinas .img_avenas_harinas {
    width: 100%;
  
  }
  
  .avenas_harinas .nutricion {
  
    width: 80%;
    height: auto;
    margin: 3% 20%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
  
  .avenas_harinas .nutricion1,
  .avenas_harinas .nutricion2,
  .avenas_harinas .nutricion3,
  .avenas_harinas .nutricion4 {
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin: auto;
    align-items: center;
    font-size: 15px;
    color: #939394;
  }
  
  .avenas_harinas .nutricion p {
    text-align: justify;
    margin: auto;
  
  }
  
  .avenas_harinas .nutricion img {
    width: 70px;
    margin: auto;
  
  }
  
  
  .avenas_harinas .nutricion img:hover {
    transform: scale(1.1);
  }
  
  
  .avenas_harinas .img_avenas_harinas img {
    width: auto;
    height: 90%;
    padding-top: 5%;
    margin: auto;
  }
  
  
  
  .harinas_instagram {
    width: 100%;
    height: auto;
    background-color: #e1e1e1;
    margin: auto;
    padding-bottom: 50px;
  }
  
  
  @media (max-width: 722px) {
    .primary_harinas {

      height: 30vh;
      background-size: cover;
      background-position: top;
  }


    .primary_harinas .txt_harinas {
      margin: auto;
      -webkit-text-stroke-width: 0.6px;
      -webkit-text-stroke-color: #fff;
      text-shadow: rgba(0, 0, 0, 0.5) 0em 0.1em 0.1em;
    }
  
    .primary_harinas .title_harinas {
      margin: auto;
      font-size: 12vh;
      font-weight: 700;
      box-shadow: #ffffff;
    }
  
    .tlt_harinas {
      height: auto;
      font-size: 2.5vh;
    }
  
    .avenas_harinas {
      grid-template-columns: 1fr;
      height: auto;
    }
  
  
    .avenas_harinas .txt_avenas_harinas h2 {
      margin: auto;
      font-size: 4vh;
      text-align: center;
    }
  
    .avenas_harinas .txt_avenas_harinas h3 {
      font-size: 3.5vh;
      text-align: center;
      margin: auto;
  
    }
  
    .avenas_harinas .txt_avenas_harinas .boton_harinas {
      width: 25vh;
      height: 6vh;
      margin: auto;
      margin-top: 5%;
      margin-bottom: 5%;
      border-radius: 80%;
    }
  
    .avenas_harinas .img_avenas_harinas img {
      width: 35vh;
      height: 90%;
      padding-top: 4%;
      margin: auto;
      margin-top: 2%;
      box-shadow: 2px 2px 2px 4px rgb(0 0 0 / 30%);
      border-radius: 10%;
    }
  
  
    .avenas_harinas .nutricion {
      width: 100%;

      height: auto;
      margin: auto;
      margin-top: 15%;
      grid-template-columns: 1fr 1fr;
    }
  
    .avenas_harinas .nutricion1,
    .avenas_harinas .nutricion2,
    .avenas_harinas .nutricion3,
    .avenas_harinas .nutricion4 {
      grid-template-columns: 1fr 4fr;
      text-align: justify;
      justify-items: start;
      margin-left: 0;
      font-size: 1.8vh;
    }
  
    .avenas_harinas .nutricion1,
    .avenas_harinas .nutricion2,
    .avenas_harinas .nutricion3,
    .avenas_harinas .nutricion4 p {
      margin-left: 0;
    }
  
  
    .avenas_harinas .nutricion1,
    .avenas_harinas .nutricion2,
    .avenas_harinas .nutricion3,
    .avenas_harinas .nutricion4 img {
      width: 7vh;
    }
  
    .avenas_harinas .nutricion img {
      width: 7vh;
    }
  

  }