.boton-pequeno {
  position: fixed;
  padding: 5px 10px;
  bottom: 111px;
  right: 0px;
  z-index: 1000;
  width: 130px;
  height: 40px;
  background-color: #006450;
  color: #ccc;
  border-radius: 56px 0px 0px 56px;
  border: #ccc;
  font-size: 13px;
  font-weight: 600;
  text-align: right;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}

.boton-pequeno:hover {
  background-color: #0b997d;
}

.popup {
  width: 200px;
  display: none;
  position: fixed;
  right: 50px;
  bottom: 165px;
  background-color: rgba(0, 0, 0, 0.6);
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
  border-radius: 30px;
  margin: auto;
}

.popup ul, .popup li {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin: auto;
}

.popup.mostrar {
  height: auto;
  display: block;
  opacity: 1;
  z-index: 400;
}

.popup img {
  width:80px;
  height: auto;
  opacity: 1;
  margin: auto;
  z-index: 400;
  margin: 5%;
}

.mostrar {
  animation: mostrarPopup 0.5s forwards;
}

.ocultar {
  animation: ocultarPopup 0.5s forwards;
  z-index: 400;
}

@keyframes mostrarPopup {
  from {
      transform: translateX(100%);
  }
  to {
      transform: translateX(0);
  }
}

@keyframes ocultarPopup {
  from {
      transform: translateX(0);
  }
  to {
      transform: translateX(100%);
  }
}
