

.primary_granola {
    display: grid;
    grid-template-columns: 1fr 1fr;
    overflow: hidden;
    width: 100%;
    height: 60vh;
    background-image: url("../../../design/del_sur/Elements/Granola_header.png");
    background-size: cover;
  
  
  }
  
  .primary_granola .txt_granola {
    margin: auto;
  }
  
  .primary_granola .title_granola {
    margin: auto 25%;
    font-size: 16vh;
    font-weight: 700;
    color: #509775;
  }
  
  .tlt_granola {
    width: 100%;
    height: auto;
    margin: auto;
    background: linear-gradient(70deg, #529977, #65bc94);
    font-size: 4vh;
    margin: auto;
  
  }
  
  .tlt_granola h1 {
    margin: auto;
    align-items: center;
    padding-top: 5px;
  }
  
  .avenas_granola {
    width: 100%;
    height: 100vh;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-image: url("../../../design/del_sur/Fondos/Home_alimentacion_bk.png");
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    background-color: #e1e1e1;
  }
  
  .avenas_granola .txt_avenas_granola h2 {
    width: 100%;
    margin: 3% 20%;
    font-size: 6.2vh;
    font-weight: 800;
    color: #509775;
    text-align: justify;
  
  }
  
  .avenas_granola .txt_avenas_granola h3 {
    font-size: 3.5vh;
    font-weight: 600;
    color: #939394;
    text-align: justify;
    margin: 0% 20%;
  }
  
  .avenas_granola .txt_avenas_granola .boton_granola {
    width: 25vh;
    height: 6vh;
    margin: 3% 20%;
    margin-right: 55%;
    background-color: rgba(0, 66, 150, 0.3);
    color: #2b579e;
    border-color: #004296;
    font-size: 2.5vh;
  }
  
  
  
  .avenas_granola .img_avenas_granola {
    width: 100%;
  
  }
  
  .avenas_granola .nutricion {
  
    width: 80vh;
    height: 15vh;
    margin: 3% 20%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
  
  
  .avenas_granola .nutricion1,
  .avenas_granola .nutricion2,
  .avenas_granola .nutricion3,
  .avenas_granola .nutricion4 {
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin: auto;
    align-items: center;
    font-size: 2.5vh;
    color: #939394;
  }
  
  .avenas_granola .nutricion p {
    text-align: justify;
    margin: auto;
  
  }
  
  .avenas_granola .nutricion img {
    width: 10vh;
    margin: auto;
  
  }
  
  
  .avenas_granola .nutricion img:hover {
    transform: scale(1.1);
  }
  
  
  .avenas_granola .img_avenas_granola img {
    width: 100%;
    padding-top: 10%;
    margin: auto;
  }
  
  
  /*GRANOLA FRUTOS ROJOS*/
  
  
  .avenas_granola_frutos {
    width: 100%;
    height: 90vh;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    /*background-image: ;*/
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    background-color: #e1e1e1;
  }
  
  .avenas_granola_frutos .txt_avenas_granola h2 {
    width: 100%;
    margin: 3% 0%;
    font-size: 6.2vh;
    font-weight: 800;
    color: #ba425f;
    text-align: justify;
  
  }
  
  .avenas_granola_frutos .txt_avenas_granola h3 {
    font-size: 3.5vh;
    font-weight: 600;
    color: #939394;
    text-align: justify;
    margin: 0% 0%;
  }
  
  .avenas_granola_frutos .txt_avenas_granola .boton_granola_frutos {
    width: 25vh;
    height: 6vh;
    margin: 3% 0%;
    margin-right: 55%;
    background-color: rgba(0, 66, 150, 0.3);
    color: #2b579e;
    border-color: #004296;
    font-size: 2.5vh;
  }
  
  
  
  .avenas_granola_frutos .img_avenas_granola {
    width: 100%;
  
  }
  
  .avenas_granola_frutos .nutricion {
  
    width: 80vh;
    height: 15vh;
    margin: 3% 0%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
  
  
  .avenas_granola_frutos .nutricion1,
  .avenas_granola_frutos .nutricion2,
  .avenas_granola_frutos .nutricion3,
  .avenas_granola_frutos .nutricion4 {
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin: auto;
    align-items: center;
    font-size: 2.5vh;
    color: #939394;
  }
  
  .avenas_granola_frutos .nutricion p {
    text-align: justify;
    margin: auto;
  
  }
  
  .avenas_granola_frutos .nutricion img {
    width: 10vh;
    margin: auto;
  
  }
  
  
  .avenas_granola_frutos .nutricion img:hover {
    transform: scale(1.1);
  }
  
  
  .avenas_granola_frutos .img_avenas_granola img {
    width: 100%;
    height: auto;
    margin: auto;
  }
  
  
  
  
  /*GRANOLA CHOCOLATE*/
  
  
  .avenas_granola_choco {
    width: 100%;
    height: 100vh;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-image: url("../../../design/del_sur/Fondos/Home_alimentacion_bk.png");
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    background-color: #e1e1e1;
  }
  
  .avenas_granola_choco .txt_avenas_granola_choco h2 {
    width: 100%;
    margin: 3% 20%;
    font-size: 6.2vh;
    font-weight: 800;
    color: #bc895f;
    text-align: justify;
  
  }
  
  .avenas_granola_choco .txt_avenas_granola_choco h3 {
    font-size: 3.5vh;
    font-weight: 600;
    color: #939394;
    text-align: justify;
    margin: 0% 20%;
  }
  
  .avenas_granola_choco .txt_avenas_granola_choco .boton_granola_choco {
    width: 25vh;
    height: 6vh;
    margin: 3% 20%;
    margin-right: 55%;
    background-color: rgba(0, 66, 150, 0.3);
    color: #2b579e;
    border-color: #004296;
    font-size: 2.5vh;
  }
  
  
  
  .avenas_granola_choco .img_avenas_granola_choco {
    width: 100%;
  
  }
  
  .avenas_granola_choco .nutricion {
  
    width: 80vh;
    height: 15vh;
    margin: 3% 20%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
  
  
  .avenas_granola_choco .nutricion1,
  .avenas_granola_choco .nutricion2,
  .avenas_granola_choco .nutricion3,
  .avenas_granola_choco .nutricion4 {
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin: auto;
    align-items: center;
    font-size: 2.5vh;
    color: #939394;
  }
  
  .avenas_granola_choco .nutricion p {
    text-align: justify;
    margin: auto;
  
  }
  
  .avenas_granola_choco .nutricion img {
    width: 10vh;
    margin: auto;
  
  }
  
  
  .avenas_granola_choco .nutricion img:hover {
    transform: scale(1.1);
  }
  
  
  .avenas_granola_choco .img_avenas_granola_choco img {
    width: 90%;
    height: auto;
    padding-top: 5%;
    margin: auto;
  }




  @media (max-width: 722px) {

    .primary_granola{
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      height: 30vh;
    }
  
    .primary_granola .txt_granola {
      margin: auto;
    }
  
    .primary_granola .title_granola {
      margin: auto;
      font-size: 10vh;
      font-weight: 700;
      text-shadow: #fff 0 -2.5px;
    }
  
    
    .tlt_granola {
      height: auto;
      font-size: 1.1em;
    }
  
    .avenas_granola,
    .avenas_granola_frutos,
    .avenas_granola_choco
     {
      height: auto;
      grid-template-columns: 1fr;
      padding-bottom: 35%;
    }
  
    .avenas_granola .txt_avenas_granola h2 {
      margin: 3% 0%;
      font-size: 5vh;
      text-align: center;
  }
    
  .avenas_granola .txt_avenas_granola h3{
    margin: 3% 0%;
    font-size: 3vh;
    text-align: center;
  }
  
  .avenas_granola .txt_avenas_granola .boton_granola{
    width: 25vh;
    height: 6vh;
    margin: auto;
    margin-top: 5%;
    margin-bottom: 5%;
    border-radius: 80%;
  } 
  
  .avenas_granola .nutricion {
    width: 100%;
    height: auto;
    margin: auto;
    margin-bottom: 8vh;
    display: grid;
    width: 100%;
    height: auto;
    margin: auto;
    grid-template-columns: 1fr 1fr;
  }
  
  
  .avenas_granola .nutricion1,
  .avenas_granola .nutricion2,
  .avenas_granola .nutricion3,
  .avenas_granola .nutricion4 {
    grid-template-columns: 1fr 4fr;
    text-align: justify;
    justify-items: start;
    margin-left: 0;
    font-size: 1.8vh;
  }
  
  .avenas_granola .nutricion1,
  .avenas_granola .nutricion2,
  .avenas_granola .nutricion3,
  .avenas_granola .nutricion4 p {
    margin-left: 0;
  }
  
  
  .avenas_granola .nutricion1,
  .avenas_granola .nutricion2,
  .avenas_granola .nutricion3,
  .avenas_granola .nutricion4 img {
    width: 7vh;
  }
  
  .avenas_granola .nutricion img {
    width: 7vh;
  }
  
  .avenas_granola_frutos{
    display: grid;
    grid-template-areas: 
    "txt_avenas_granola txt_avenas_granola txt_avenas_granola"
    "img_avenas_granola img_avenas_granola img_avenas_granola";
  }
  
  .avenas_granola_frutos .img_avenas_granola{
    grid-area: img_avenas_granola;
  }
  
  .avenas_granola_frutos .txt_avenas_granola{
    grid-area: txt_avenas_granola;
  }
  
  
  .avenas_granola_frutos .txt_avenas_granola h2{
    margin: 3% 0%;
    font-size: 5vh;
    text-align: center;
  }
  
  .avenas_granola_frutos .txt_avenas_granola h3{
  margin: 3% 0%;
  font-size: 3vh;
  text-align: center;
  }
  
  .avenas_granola_frutos .txt_avenas_granola .boton_granola_frutos{
  width: 25vh;
  height: 6vh;
  margin: auto;
  margin-top: 5%;
  margin-bottom: 5%;
  border-radius: 80%;
  } 
  
  .avenas_granola_frutos .nutricion {
  width: 100%;
  height: auto;
  margin: auto;
  margin-bottom: 8vh;
  display: grid;
  width: 100%;
  height: auto;
  margin: auto;
  grid-template-columns: 1fr 1fr;
  }
  
  
  .avenas_granola_frutos .nutricion1,
  .avenas_granola_frutos .nutricion2,
  .avenas_granola_frutos .nutricion3,
  .avenas_granola_frutos .nutricion4 {
  grid-template-columns: 1fr 4fr;
  text-align: justify;
  justify-items: start;
  margin-left: 0;
  font-size: 1.8vh;
  }
  
  .avenas_granola_frutos .nutricion1,
  .avenas_granola_frutos .nutricion2,
  .avenas_granola_frutos .nutricion3,
  .avenas_granola_frutos .nutricion4 p {
  margin-left: 0;
  }
  
  
  .avenas_granola_frutos .nutricion1,
  .avenas_granola_frutos .nutricion2,
  .avenas_granola_frutos .nutricion3,
  .avenas_granola_frutos .nutricion4 img {
  width: 7vh;
  }
  
  .avenas_granola_frutos .nutricion img {
  width: 7vh;
  }
  
  
  
  
  .avenas_granola_choco .txt_avenas_granola_choco  h2{
    margin: 3% 0%;
    font-size: 5vh;
    text-align: center;
  }
  
  .avenas_granola_choco .txt_avenas_granola_choco h3{
  margin: 3% 0%;
  font-size: 3vh;
  text-align: center;
  }
  
  .avenas_granola_choco .txt_avenas_granola_choco .boton_granola_choco{
  width: 25vh;
  height: 6vh;
  margin: auto;
  margin-top: 5%;
  margin-bottom: 5%;
  border-radius: 80%;
  } 
  
  .avenas_granola_choco .nutricion {
  width: 100%;
  height: auto;
  margin: auto;
  margin-bottom: 8vh;
  display: grid;
  width: 100%;
  height: auto;
  margin: auto;
  grid-template-columns: 1fr 1fr;
  }
  
  
  .avenas_granola_choco .nutricion1,
  .avenas_granola_choco .nutricion2,
  .avenas_granola_choco .nutricion3,
  .avenas_granola_choco .nutricion4 {
  grid-template-columns: 1fr 4fr;
  text-align: justify;
  justify-items: start;
  margin-left: 0;
  font-size: 1.8vh;
  }
  
  .avenas_granola_choco .nutricion1,
  .avenas_granola_choco .nutricion2,
  .avenas_granola_choco .nutricion3,
  .avenas_granola_choco .nutricion4 p {
  margin-left: 0;
  }
  
  
  .avenas_granola_choco .nutricion1,
  .avenas_granola_choco .nutricion2,
  .avenas_granola_choco .nutricion3,
  .avenas_granola_choco .nutricion4 img {
  width: 7vh;
  }
  
  .avenas_granola_choco .nutricion img {
  width: 7vh;
  }





  }


