@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap');

:root {
  --color-primario: #FCB03B;
  --color-secundario: #E92F3D;
  --color-backgroud-sombra: #E92F3D30;
  --color-background: #FCF2E6;
  --color-texto: #A1A5A6;
  --color-pronalce1: #73833d;
  --color-pronalce2: #6d5b46;
  --color-facebook: #3b5998;
  --color-instragram: ;
  --color-likedin: #0e76a8;
  --color-sociales: #73833d;
  --fondo-icono-cumey: rgb(128, 141, 61);
  --fondo-icono-sur: rgb(115, 129, 52);
  --fondo-icono-chocotom: rgb(103, 116, 43);
  --fondo-icono-sanesteban: rgb(89, 103, 33);
}



/*GENERALES*/
* {
  font-family: 'Ubuntu', sans-serif;
  text-align: center;
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}


.grid_nosotros{
  box-shadow: 0px 2px 2px 5px rgba(0, 0, 0, 0.2);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: auto;
  grid-template-areas:
    "nosotros nosotros nosotros"
    "texto_nosotros texto_nosotros texto_nosotros"
    "historia_tiempo historia_tiempo historia_tiempo"
    "empleados empleados empleados"
    "video-pro_colombia video-pro_colombia video-pro_colombia"
    "footer footer footer"
    "copyright copyright copyright"
  ;
}


.nosotros {
  grid-area: nosotros;
  width: 100%;
  height: 300px;
  margin: auto;
  background-image: url("../../../design/nosotros/bk_header_nosotros.webp");
  background-attachment: 100%;
  background-size: cover;
  background-repeat: no-repeat;



}

.nosotros h1 {
  color: #fff;
  font-size: 96px;
  font-weight: 700;
  margin: auto;
  margin-top: 80px;
  text-shadow: rgba(0, 0, 0, 0.5) 0em 0.1em 0.1em;
  -webkit-text-stroke-width: 0.6px;
  -webkit-text-stroke-color: black;
}


.texto_nosotros {
  width: 100%;
  height: auto;
  grid-area: texto_nosotros;
  margin: auto;
  background-image: url("../../../design/nosotros/bk_green_leaves.webp");
  background-repeat: space;

}
.texto_fondo_nosotros{
  width: 70%;
  margin: auto;
  margin-top: 100px;
}

.texto_fondo_nosotros h1 {
  font-size:60px;
  font-weight: 800;
  text-align: center;
  margin-bottom: 100px;
  color: var(--color-pronalce1);
}

.texto_fondo_nosotros  {
  font-size: 30px;
  text-align: start;
}

.texto_nosotros Strong {
  font-size: 35px;
  color: var(--color-pronalce1);
}

.historia_tiempo {
  grid-area: historia_tiempo;
  width: 100%;
  height: auto;
  margin: auto;
  margin-bottom: 50px;
}


.empleados {
  grid-area: empleados;
  width: 100%;
  height:auto ;
  margin: auto;
  background-image: url("../../../design/nosotros/Planta_blue.webp");
  background-attachment: fixed;
  background-size: cover;
  background-position: 0,0;
  display: grid;
  grid-template-columns: 1fr 1fr;
}



.texto_empleados {
  width: 100%;
height: auto;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 100px;
}

.texto_empleados p {
  width: 90%;
  text-align: left;
  font-size: 15px;
  margin: auto;
  margin-top: 100px;
  line-height: 16px;
  color: #FFF;
  text-align: left;
  padding-left: 30%;
}


.texto_empleados h1 {
  width: 100%;
  font-size: 70px;
  font-weight: 600;
  margin: auto;
  color: #FFF;
  text-align: right;
}

.pro_colombia {
  width: 80%;
  height: 700px;
  grid-area: video-pro_colombia;
  padding: 30px;
  margin: auto;

}


.footer {
  grid-area: footer;
  width: 100%;
  z-index: 10;
}


.copyright {
  grid-area: copyright;

}




@media (max-width: 722px) {

  .header{
    position: fixed;
    z-index: 100;
  }

  .header .banner1{
    display: none;
  }

  .header .menu-principal{
    background-color: rgb(254, 254, 254, 0.60);
    backdrop-filter: blur(8px);
  }

.nosotros {
    width: 100%;
    height: 180px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 5px;
    background-image: url("../../../design/nosotros/main_banner_nosotros.webp");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    box-shadow: 2px 2px 2px 4px rgb(0 0 0 / 30%);
}


.nosotros h1 {
  font-size: 5vh;
  margin-top: 100px;
}

.texto_fondo_nosotros {
  width: 90%;
  margin: auto;
  margin-top: 50px;
}

.texto_fondo_nosotros h1 {
  font-size:6vh;
  font-weight: 800;
  text-align: center;
  margin-bottom: 20px;
  color: var(--color-pronalce1);
}

.texto_fondo_nosotros p {

  font-size:2vh;
}

.texto_nosotros Strong {
  font-size: 2.5vh;
}

.texto_empleados p {
  width: 150%;
  font-size:16;
  margin: 15%; 
  text-align: center;
  font-size: 15px;
  margin-top: -30px;
  margin-bottom: 50px;
  line-height: 18px;
}


.texto_empleados h1 {
  width: 120%;
  font-size:3vh;
  margin:calc(50% - 10px);
  text-align: center;
}

.pro_colombia {
  width: 100%;
  height: 300px;
  padding: 0px;
  margin-top: 0px;

}


}