:root {
	--color-primario: #FCB03B;
	--color-secundario: #E92F3D;
	--color-backgroud-sombra: #E92F3D30;
	--color-background: #FCF2E6;
	--color-texto: #A1A5A6;
	--color-pronalce1: #73833d;
	--color-pronalce2: #6d5b46;
	--color-facebook: #3b5998;
	--color-instragram: ;
	--color-likedin: #0e76a8;
	--color-sociales: #73833d;
	--fondo-icono-cumey: rgb(128, 141, 61);
	--fondo-icono-sur: rgb(115, 129, 52);
	--fondo-icono-chocotom: rgb(103, 116, 43);
	--fondo-icono-sanesteban: rgb(89, 103, 33);
  }


.carousel{
	margin-top: 0px;
}

.carousel__contenedor {
    position: relative;
}

.carousel__anterior,
.carousel__siguiente {
	position: absolute;
    display: block;
    width: 50px;
    height: 50px;
    border: none;
    top: calc(50% - 35px);
    cursor: pointer;
    line-height: 30px;
    text-align: center;
    background: none;
    color: white;
	background-color: var(--color-pronalce1);
    opacity: 50%;
    z-index: 80;

}

.carousel__anterior:hover,
.carousel__siguiente:hover {
	opacity: 100%;
	box-shadow: 0px 3px 0px 0.1px rgba(0, 0, 0, 0.2);

}
.carousel__siguiente {
right: 10px;

}

.carousel__anterior {
	left: 10px;
}

.carousel__lista{
	overflow: hidden;
}
.carousel__elemento{
display: grid;
grid-template-columns: 1fr;

}


.carousel__elemento p{
	width: 60%;
	margin: auto;
	color: var(--color-pronalce2);
	text-align: center;
	font-size: 23px;
}

.carousel__elemento Strong{

	color: var(--color-pronalce1);
	font-size: 30px;
}


.carousel__elemento img{
	margin: auto;

}

.carousel__indicadores .glider-dot{
	display: block;
	width: 20px;
	height: 20px;
    background: var(--color-pronalce1);
    color: var(--color-pronalce1);
	opacity: .2;
	

}

.carousel__indicadores .glider-dot:hover{
opacity: .5;
background: var(--color-pronalce1);
color: var(--color-pronalce1);
}

.carousel__indicadores .glider-dot:active{
	opacity: 1;
	}

	@media (max-width: 722px) {

		.carousel__elemento p{

			font-size: 15px;
		}
		
		.carousel__elemento Strong{
		
			color: var(--color-pronalce1);
			font-size: 30px;
		}

	.carousel__anterior,
	.carousel__siguiente {
    width: 25px;
    height: 50px;

		}

		.carousel__elemento img{
			width: 80%;
			margin: auto;
		
		}


	}