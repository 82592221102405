@import url('https://fonts.googleapis.com/css2?family=Madimi+One&display=swap');

section {

    width: 100% !important;
    margin: auto;
    background-image: url(../../../design//politicas/background_pronalce_1080.png);
    background-size: 100%;
    background-repeat: no-repeat;

}

section span {

    margin-left: 50%;
    font-size: 10px;
    font-weight: 600;
}

section h1 {
    font-family: "Madimi One", sans-serif;
    font-weight: 400;
    text-align: center;
    font-size: 70px;
    color: black;
    text-shadow: #9ba56d 0.1em 0.1em 0.2em;
    -webkit-text-stroke-width: 0.6px;
    -webkit-text-stroke-color: #fff;

}

section .politicas-img1 {
    width: 65%;
    height: auto;
}


section .one-politica,
section .two-politica,
section .tree-politica {
    width: 70%;
    margin: auto;
    margin-top: 10%;
}

section .one-politica .title-politica {
    width: 300px;
    float: right;
    margin: 0;
    margin-top: -5%;
}


section .two-politica .title-politica {
    width: 600px;
    float: left;
    margin: 0;
    margin-top: -5%;
}

section .tree-politica .title-politica {
    width: 900px;
    float: right;
    margin: 50px;
    margin-top: -5%;
}

section .one-politica .title-politica h1 {
    width: 100%;
    height: 60px;
    background-color: #ff8c09;
    margin: auto;
    text-align: center;
    font-size: 50px;
    color: #fff;
    border-radius: 30px;
    text-shadow: #000 0.1em 0.1em 0.4em;
    -webkit-text-stroke-width: 0.6px;
    -webkit-text-stroke-color: #000;

}

section .two-politica .title-politica h1 {
    width: 100%;
    height: 70px;
    background-color: #004b87ff;
    margin: auto;
    text-align: center;
    font-size: 50px;
    color: #fff;
    border-radius: 30px;
    text-shadow: #000 0.1em 0.1em 0.4em;
    -webkit-text-stroke-width: 0.6px;
    -webkit-text-stroke-color: #000;

}

section .tree-politica .title-politica h1 {
    width: 100%;
    height: 140px;
    background-color: #acbd66;
    margin: auto;
    text-align: center;
    font-size: 50px;
    color: #fff;
    border-radius: 30px;
    text-shadow: #000 0.1em 0.1em 0.4em;
    -webkit-text-stroke-width: 0.6px;
    -webkit-text-stroke-color: #000;

}

section .one-politica .text-politica,
section .two-politica .text-politica,
section .tree-politica .text-politica {
    width: 100%;
    margin: auto;
    border-radius: 20px;
    background-color: #dadbd9ff;
    padding: 3% 6%;
}

section .one-politica .text-politica p,
section .two-politica .text-politica p,
section .tree-politica .text-politica p {
    margin-top: 20px;
    text-align: left;
    font-size: 22px;
}

section .one-politica .text-politica b {
    font-size: 25px;
}

section .one-politica .text-politica h3 {
    font-size: 30px;
    font-weight: 500;
    text-align: right;
}

section .one-politica #p2 {
    text-align: left;
    font-size: 10px;
    padding-left: 20px;
}


@media (max-width: 722px) {

    section {
        margin-top: 10%;
    }

    section h1 {
        font-size: 40px;
    }

    section .politicas-img1 {
        width: 100%;
        height: auto;
    }

    section .one-politica,
    section .two-politica,
    section .tree-politica {
        width: 100%;
        margin: auto;
        margin-top: 10%;
        margin-bottom: 10%;
    }




    section .one-politica .title-politica ,
    section .tree-politica .title-politica,
    section .two-politica .title-politica {
        width: 100%;
        float: center;
        margin: auto;
        margin-top: -5%;
    }

 
    section .two-politica .title-politica h1,
    section .tree-politica .title-politica h1,
    section .one-politica .title-politica h1 {
        width: 100%;
        height: auto;
        margin: auto;
        text-align: center;
        font-size: 30px;
        color: #fff;

    }    

section .tree-politica .text-politica h3, 
section .one-politica .text-politica h3 {
    font-size: 20px;
    text-align: center;
    color: white;
    text-shadow: #000 0.1em 0.1em 0.8em;
    -webkit-text-stroke-width: 0.6px;
    -webkit-text-stroke-color: #000;
}


section .one-politica .text-politica p,
section .two-politica .text-politica p,
section .tree-politica .text-politica p {
    margin-top: 20px;
    text-align: left;
    font-size: 18px;
}

section .one-politica .text-politica b {
    font-size: 20px;
}

}