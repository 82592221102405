body {
  margin: 0;
  padding: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  font-family: 'Roboto', sans-serif;
}

/* Contenedor general del contenido */
.cookie-policy-container {
    max-width: 900px;
    margin: 50px auto;
    padding: 40px;
    background-color: #ffffff;
    border-radius: 15px;
    box-shadow: 0 6px 25px rgba(0, 0, 0, 0.1);
    font-family: 'Roboto', sans-serif;
    line-height: 1.8;
    border: 1px solid #73833d;
  }
  
  /* Título principal */
  .cookie-policy-container h2 {
    text-align: center;
    font-size: 34px;
    color: #73833d;
    margin-bottom: 20px;
  }
  
  /* Títulos secundarios */
  .cookie-policy-container h3 {
    font-size: 22px;
    color: #555555;
    margin-top: 30px;
    border-bottom: 2px solid #73833d;
    padding-bottom: 8px;
  }
  
  /* Párrafo de introducción */
  .intro-text {
    font-size: 18px;
    color: #666666;
    margin-bottom: 20px;
  }
  
  /* Estilo de los párrafos */
  .cookie-policy-container p {
    font-size: 16px;
    color: #666666;
    margin-bottom: 20px;
    text-align: justify;
  }
  
  /* Estilo de las listas */
  .cookie-list, .third-party-list {
    list-style-type: disc;
    margin-left: 20px;
    color: #666666;
    font-size: 16px;
  }
  
  /* Elementos de las listas */
  .cookie-list li, .third-party-list li {
    margin-bottom: 10px;
  }
  
  /* Enlaces */
  .cookie-policy-container a {
    color: #73833d;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  .cookie-policy-container a:hover {
    color: #556726;
  }
  

  .cookie-list{
    list-style-type: disc;
    margin-left: 20px;
    color: #000;
    font-size: 16px;
    
  }

  .cookie-list li{
    margin-bottom: 10px;
    color: #000;
}
  /* Responsividad */
  @media (max-width: 768px) {
    .cookie-policy-container {
 
      padding: 20px;
      margin: 20px;
      margin-top: 10%;
    }
  
    .cookie-policy-container h2 {
      font-size: 28px;
    }
  
    .cookie-policy-container h3 {
      font-size: 20px;
    }
  
    .cookie-policy-container p, .cookie-list li, .third-party-list li {
      font-size: 14px;
    }
  }
  