

.content h1 {
    color: #193848;
    text-align: center;
    text-transform: uppercase;
    font: 900 2.5rem 'Montserrat';


}

#loader{
	position: fixed;
	width: 100%;
	height: 110%;
	z-index: 999;
    background-color: #fff;
    top: 0%;
}

.loader {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 1000;
    width: 100%;
    left: 0%;
    margin: auto;
    justify-content: space-around;
    border: 1PX;
    align-items: center;
    justify-items: center;
    position: fixed;


}

.loader img{
    margin: auto;
    width: 200px;
    height: auto;
    align-items: center;
}



.loader .loader-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


@keyframes loading {
    0%, 100% {
        transform: translate(0);
    }

    25% {
        transform: translate(80px);
    }

    50% {
        transform: translate(80px, 80px);
    }

    75% {
        transform: translate(0, 80px);
    }
}

.loader.fadeOut {
    animation: fadeOut 1s alternate;

}

@keyframes fadeOut {
    to {
        opacity: 0;
    }
}


